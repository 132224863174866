import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../helpers/store.hook";
import { AppDispatch } from "../../../../store";
import { SelectStyled } from "../../../common/Select";
import { Brands } from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";
import { getModelsThunk } from "../../../filters/redux/filters.thunks";

const BrandSelect = SelectStyled<Brands>();

export const BrandSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const brands = useAppSelector((state) => state.filters.brands);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData?.brand);

  const handleBrand = (value: string, options: Brands) => {
    if (selectedBrand?.name !== value) {
      dispatch(
        getModelsThunk({
          ...options,
          name: value,
        }),
      );
    }
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearBrand());
  };

  return (
    <BrandSelect
      options={brands}
      value={selectedBrand?.name}
      onSelect={handleBrand}
      onClear={handleClear}
      showSearch
      allowClear
      placeholder={t("select")}
    />
  );
};
