import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { InputNumberStyled } from "../../../common/InputNumber";
import { FromToEnum } from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";

const PricePickerWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const PriceInput = InputNumberStyled<number>();

export const PriceSearch = () => {
  return (
    <>
      <PricePickerWrapperStyled>
        <PriceInputComp type={FromToEnum.FROM} />
        <PriceInputComp type={FromToEnum.TO} />
      </PricePickerWrapperStyled>
    </>
  );
};

const PriceInputComp = React.memo(({ type }: { type: FromToEnum }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const price = useAppSelector((state) => state.filters.selectedData.price);
  const [state, setState] = useState<number | null>(null);

  useEffect(() => {
    setState(price?.[type] || null);
  }, [price, type]);
  const handleChange = (value: number | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(String(value)) || String(value) === "" || value === null) {
      setState(value);
    }
  };

  const handleBlur = () => {
    dispatch(FiltersActions.setPrice({ type, value: state || undefined }));
  };
  return (
    <PriceInput
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={t(type)}
      maxLength={7}
      value={state}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
      prefix="$"
      controls={false}
    />
  );
});
