import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { SelectStyledMulty } from "../../searchFilter";

export const BodyTypeSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { manageAdvanceSearch } = useSearch();
  const bodyTypes = useAppSelector((state) => state.car.bodyType);
  const selectedBodyType = useAppSelector((state) => state.filters.selectedData?.bodyType);

  const handleBodyType = (value: string[]) => {
    dispatch(FiltersActions.setBodyTypes(value));
    manageAdvanceSearch("bodyType", value);
  };
  return (
    <SelectStyledMulty
      options={bodyTypes}
      value={selectedBodyType}
      onChange={handleBodyType}
      mode="multiple"
      size="large"
      placeholder={t("addCar.bodyType.label")}
      showSearch
      allowClear
    />
  );
};
