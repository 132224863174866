import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";

const ColorItemWrapperStyled = styled.div<{
  $isSelected?: boolean;
  $value: string;
  $black?: boolean;
}>`
  border: 1px solid ${(props) => (props.$isSelected ? "var(--main-color)" : "rgb(219, 219, 219)")};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  & svg {
    position: absolute;
    ${(props) => (props.$isSelected ? "display: block" : "display: none")};

    & path {
      ${(props) => (props.$black ? "fill: rgb(0,0,0)" : "fill: rgb(255,255,255)")};
    }
  }
`;

const ColorItemStyled = styled.div<{ $isSelected?: boolean; $value: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.$value};
`;

const ColorPickerWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const colorsWhite = ["white", "yellow", "beige"];

export const ColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { manageAdvanceSearch } = useSearch();
  const colors = useAppSelector((state) => state.car.color);

  const selectedColor = useAppSelector((state) => state.filters.selectedData?.color);

  const hangleColor = (value: string) => {
    const isExist = selectedColor?.includes(value);
    let nextColors: string[] = [];
    if (isExist && selectedColor) {
      nextColors = selectedColor?.filter((color) => color !== value);
    } else {
      nextColors = [...(selectedColor || []), value];
    }
    dispatch(FiltersActions.setColors(nextColors));
    manageAdvanceSearch("color", nextColors);
  };

  return (
    <ColorPickerWrapperStyled>
      {colors.map((color, index) => (
        <ColorItemWrapperStyled
          key={color.searchName}
          $isSelected={selectedColor?.includes(color.searchName)}
          $value={color.searchName}
          $black={colorsWhite.includes(color.searchName)}
          onClick={() => hangleColor(color.searchName)}
        >
          <ColorItemStyled
            $isSelected={selectedColor?.includes(color.searchName)}
            $value={color.searchName}
          />
          <svg
            width="15.569000"
            height="11.303711"
            viewBox="0 0 15.569 11.3037"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="check"
              d="M5.33 11.3L0 5.96L1.06 4.9L5.33 9.16L14.5 0L15.56 1.06L5.33 11.3Z"
              fill="#FAFAFA"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </ColorItemWrapperStyled>
      ))}
    </ColorPickerWrapperStyled>
  );
};
