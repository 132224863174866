import { CardSelect } from "../components/main/component/card/cardSelect";
import { SearchBar } from "../components/main/component/filter/SearchBar";
import { MainHistory } from "../components/main/component/history/history";
import { RandomCar } from "../components/main/component/random/random";
import { ResentCar } from "../components/main/component/recent/recent";

export const MainPage = () => {
  return (
    <>
      <SearchBar />
      <CardSelect />
      <MainHistory />
      <RandomCar />
      <ResentCar />
    </>
  );
};
