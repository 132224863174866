import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import bg from "./bg.png";
import axios from "../../../../helpers/interseptor";
import { CarRoutesPath, SearchRoutesPath } from "../../../../routes";
import { CarItem } from "../../../car/CarTypes";
import { BTNTypes, ButtonStyled } from "../../../common/Button";
import { SearchCart } from "../../../search/components/searchCart";

const RandomCarWrapperStyle = styled.div`
  background-image: url(${bg});
  padding: 64px;
  width: 100%;
  display: flex;
  margin-top: 120px;
`;

const DetailsWrapperStyle = styled.div`
  & a > button {
    padding: 16px;
    height: auto;
  }
`;

const CarWrapperStyle = styled.div`
  & > .ant-card {
    width: 470px !important;
  }
`;

const TitleStyle = styled.h4`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const DescriptionStyle = styled.div`
  /* 16 */
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: left;
  opacity: 0.5;
`;

const InfoStyle = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const InfoItemStyled = styled.div``;
const InfoItemTitleStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0;
`;

const InfoItemSubTitleStyled = styled.div`
  /* 20 */
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: left;
`;

const InfoItemDesctiptionStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  opacity: 0.5;
`;

const DividerStyled = styled.div`
  margin: 0 20px;
  background: linear-gradient(
    351deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(153, 153, 153, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 1px;
`;

export const RandomCar = () => {
  const [car, setCar] = useState<CarItem>();
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .get<CarItem[]>("search/random-car")
      .then((res) => res.data)
      .then((data) => {
        if (data.length !== 0) setCar(data[0]);
      });
  }, []);

  return (
    <RandomCarWrapperStyle>
      <DetailsWrapperStyle>
        <TitleStyle>{t("main.random.title")}</TitleStyle>
        <DescriptionStyle>{t("main.random.description")}</DescriptionStyle>
        <InfoStyle>
          <InfoItemStyled>
            <InfoItemTitleStyled>344</InfoItemTitleStyled>
            <InfoItemSubTitleStyled>All cars are for sale</InfoItemSubTitleStyled>
            <InfoItemDesctiptionStyled>
              Total number of cars available for sale on the website that can be viewed in the
              current list of offers
            </InfoItemDesctiptionStyled>
          </InfoItemStyled>
          <DividerStyled></DividerStyled>
          <InfoItemStyled>
            <InfoItemTitleStyled>24</InfoItemTitleStyled>
            <InfoItemSubTitleStyled>Added in an hour</InfoItemSubTitleStyled>
            <InfoItemDesctiptionStyled>
              The number of cars that have been added to the site in the last hour and are available
              for viewing
            </InfoItemDesctiptionStyled>
          </InfoItemStyled>
          <InfoItemStyled></InfoItemStyled>
        </InfoStyle>
        <NavLink to={SearchRoutesPath.search}>
          <ButtonStyled $type={BTNTypes.PRIMARY}>{t("main.random.action")}</ButtonStyled>
        </NavLink>
      </DetailsWrapperStyle>
      <CarWrapperStyle>
        {car && <SearchCart car={car} link={`${CarRoutesPath.car}/${car?._id}`} />}
      </CarWrapperStyle>
    </RandomCarWrapperStyle>
  );
};
