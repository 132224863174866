import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { CarModel } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { getGenerationsThunk } from "../../../../filters/redux/filters.thunks";
import { useSearch } from "../../../utils/useSearch";
import { SelectStyled } from "../../searchFilter";

export const ModelSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { manageSearch, removeSearch } = useSearch();
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData?.model);

  const handleModel = (value: string, options: CarModel & { isParent: boolean }) => {
    manageSearch("model", options.searchName);
    if (options.isParent) {
      dispatch(FiltersActions.setModel(options));
    } else {
      dispatch(getGenerationsThunk(options));
    }
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearModel());
    removeSearch("model");
  };
  return (
    <SelectStyled
      options={models}
      value={selectedModel?.searchName}
      onSelect={handleModel}
      // onChange={handleChange}
      onClear={handleClear}
      placeholder={t("addCar.model.label")}
      showSearch
      allowClear
      size="large"
    />
  );
};
