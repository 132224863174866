import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { BrandSearch } from "./brands";
import { ModelSearch } from "./models";
import { PriceSearch } from "./price";
import { SearchRoutesPath } from "../../../../routes";
import { BTNTypes, ButtonStyled } from "../../../common/Button";
import { YearSearch } from "../../../filters/components/years";
import { SearchBtn } from "../../shared/SearchBtn";

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 80px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  margin: 60px auto;
  padding: 24px;
`;

const SearchItemWrapper = styled.div<{ $end?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  ${({ $end }) => $end && "justify-content: flex-end;"}
`;

const LabelStyled = styled.div`
  color: rgb(52, 51, 57);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
`;

const ActionWrapperStyled = styled.div`
  display: flex;
  height: 54px;
  gap: 8px;
`;

export const SearchBar = () => {
  const { t } = useTranslation();

  return (
    <SearchWrapper>
      <SearchItemWrapper>
        <LabelStyled>{t("brand")}</LabelStyled>
        <BrandSearch />
      </SearchItemWrapper>
      <SearchItemWrapper>
        <LabelStyled>{t("model")}</LabelStyled>
        <ModelSearch />
      </SearchItemWrapper>

      <SearchItemWrapper>
        <LabelStyled>{t("year")}</LabelStyled>
        <YearSearch />
      </SearchItemWrapper>

      <SearchItemWrapper>
        <LabelStyled>{t("price")}</LabelStyled>
        <PriceSearch />
      </SearchItemWrapper>

      <SearchItemWrapper $end>
        <ActionWrapperStyled>
          <SearchBtn />
          <NavLink to={SearchRoutesPath.searchAdvanced}>
            <ButtonStyled $type={BTNTypes.SECONDARY}>{t("advance-search")}</ButtonStyled>
          </NavLink>
        </ActionWrapperStyled>
      </SearchItemWrapper>
    </SearchWrapper>
  );
};
