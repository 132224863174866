import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PHONE } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import {
  InputPasswordStyled,
  InputStyled,
  MaskedInputStyled,
  SubmitButtonStyled,
} from "../../../auth/login/loginForm";
import { updateSettings } from "../../../auth/redux/auth.thunks";
import { LabelStyled } from "../../../car/components/addCar/form";

const HeaderStyled = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding-bottom: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

const FormStyled = styled(Form)<{
  onFinish: (values: { phone: string; password?: string; name: string }) => void;
}>`
  max-width: 650px;
  width: 100%;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 24px;
`;

export const SettingsForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const userName = useAppSelector((state) => state.auth.name);
  const userPhone = useAppSelector((state) => state.auth.phone);
  const [phone, setPhone] = useState("");

  useEffect(() => {
    form.setFieldsValue({
      name: userName,
      phone: userPhone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5"),
    });
    setPhone(userPhone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5"));
  }, [userName, userPhone, form]);

  const onFinish = async (values: { phone: string; password?: string; name: string }) => {
    dispatch(updateSettings(values));
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
      .replace(/\s/g, "")
      .replace("+", "")
      .replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5");
    if (value.length > 17) {
      console.log("phone", phone);
      form.setFieldsValue({ phone: phone });

      return;
    }

    setPhone(value);
    form.setFieldsValue({ phone: value });
  };

  return (
    <FormStyled
      name="settings"
      layout="vertical"
      onFinish={onFinish}
      //   disabled={isPending}
      form={form}
    >
      <HeaderStyled>{t("user.settings.title")}</HeaderStyled>
      <Form.Item
        label={<LabelStyled>{t("auth.name.label")}</LabelStyled>}
        name="name"
        rules={[
          { required: true, message: t("auth.name.error") },
          { min: 2, message: t("auth.name.error-length-min", { length: 2 }) },
          { max: 30, message: t("auth.name.error-length-max", { length: 30 }) },
        ]}
      >
        <InputStyled />
      </Form.Item>
      <Form.Item
        name={PHONE}
        label={<LabelStyled>{t("auth.phone.label")}</LabelStyled>}
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t("auth.phone.error") },
          { min: 17, max: 17, message: t("auth.phone.error-format", { length: 17 }) },
        ]}
      >
        <MaskedInputStyled
          mask="+380 99 999 99 99"
          value={phone}
          alwaysShowMask={false}
          onChange={handleChangePhone}
          placeholder="+380  "
          maskChar=""
          disabled
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (props) => <Input {...props} disabled />
          }
        </MaskedInputStyled>
      </Form.Item>

      <Form.Item
        label={<LabelStyled>{t("user.settings.password.label")}</LabelStyled>}
        name="password"
        rules={[
          { required: false, message: t("auth.password.error") },
          { min: 6, message: t("auth.password.error-length", { length: 6 }) },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
            message: t("auth.password.error-format"),
          },
        ]}
      >
        <InputPasswordStyled />
      </Form.Item>
      <Form.Item
        label={<LabelStyled>{t("auth.password.repeat")}</LabelStyled>}
        name="password-repeat"
        dependencies={["password"]}
        rules={[
          { required: false, message: t("auth.password.error") },
          ({ getFieldValue }) => ({
            validator(_, value) {
              console.log("asdasd");
              if (getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("auth.password.not-match")));
            },
          }),
        ]}
      >
        <InputPasswordStyled />
      </Form.Item>

      <Form.Item>
        <SubmitButtonStyled block type="primary" htmlType="submit">
          {t("user.settings.save")}
        </SubmitButtonStyled>
      </Form.Item>
    </FormStyled>
  );
};
