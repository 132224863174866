import { Checkbox } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppSelector } from "../../../../helpers/store.hook";
import { AdditionalInfoPyaload } from "../../../car/CarTypes";

const ContainerPreviewStyled = styled.div<{
  $isSelected?: boolean;

  onClick: () => void;
}>`
  width: 160px;
  height: 115px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;

  border: ${(props) => (props.$isSelected ? "1px solid var(--main-color)" : "none")};
  border-radius: 8px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ImageContainerStyled = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

const ImageStyled = styled.img<{ size?: "small" | "large" }>`
  width: ${(props) => (props.size === "small" ? "38px" : "100%")};
  height: ${(props) => (props.size === "small" ? "38px" : "auto")};
`;

export const TitleContainerStyled = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
`;

const ContainerStyled = styled.div<{ $isSelected?: boolean }>`
  border: ${(props) =>
    props.$isSelected ? "1px solid var(--main-color)" : "1px solid rgb(219, 219, 219)"};
  border-radius: 8px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  flex-direction: row;
  //   width: 100%;
  max-height: 150px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
`;

const OptionsWparperStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 30px;
  height: 120px;
`;

const OptionsItemStyled = styled.div`
  min-width: 100px;
`;

const CheckboxTextStyled = styled.div`
  font-family: "Roboto", sans-serif;
`;

interface MultiplePickCardProps<T> {
  value: T;
  onClick: (value: T) => void;
  onOption: (value: AdditionalInfoPyaload) => void;
  size?: "small" | "large";
  img?: string;
  type: "fuel" | "gear";
}

export const MultiplePickAdditionalInfoCard = <T extends AdditionalInfoPyaload>({
  onClick,
  value,
  onOption,
  img,
  type,
}: MultiplePickCardProps<T>) => {
  const { t } = useTranslation();
  const selectedOptions = useAppSelector((state) => state.filters.selectedData[type]);

  const isSelected = useMemo(() => {
    if (value.options) {
      return value?.options?.every((option) => selectedOptions?.includes(option.value));
    } else {
      return selectedOptions?.includes(value?.value);
    }
  }, [selectedOptions, value]);

  const handleClick = () => {
    onClick(value);
  };

  const handlechange = (option: AdditionalInfoPyaload) => () => {
    onOption(option);
  };

  return (
    <ContainerStyled>
      <ContainerPreviewStyled $isSelected={isSelected} onClick={handleClick}>
        <ImageContainerStyled>
          <ImageStyled alt={value.label} src={img} />
        </ImageContainerStyled>
        <TitleContainerStyled>
          <TitleStyled>{t(value.value)}</TitleStyled>
        </TitleContainerStyled>
      </ContainerPreviewStyled>
      {value?.options && (
        <OptionsWparperStyled>
          {value?.options?.map((option) => (
            <OptionsItemStyled key={option._id}>
              <Checkbox
                onChange={handlechange(option)}
                checked={selectedOptions?.includes(option.value)}
              >
                <CheckboxTextStyled>{t(option.searchName)}</CheckboxTextStyled>
              </Checkbox>
            </OptionsItemStyled>
          ))}
        </OptionsWparperStyled>
      )}
    </ContainerStyled>
  );
};
