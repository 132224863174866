import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { SelectStyled } from "../../../common/Select";
import { CarModel } from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";
import { getGenerationsThunk } from "../../../filters/redux/filters.thunks";

const ModelSelect = SelectStyled<CarModel & { isParent: boolean }>();

export const ModelSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData?.model);

  const handleModel = (value: string, options: CarModel & { isParent: boolean }) => {
    if (options.isParent) {
      dispatch(FiltersActions.setModel(options));
      return;
    }
    dispatch(getGenerationsThunk(options));
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearModel());
  };

  return (
    <ModelSelect
      options={models}
      value={selectedModel?.searchName}
      onSelect={handleModel}
      onClear={handleClear}
      placeholder={t("select")}
      showSearch
      allowClear
    />
  );
};
