import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PreviewCarItem, SectionTitleStyled } from "../../../../pages/car/preview/PreviewCar";
import { CarItem } from "../../CarTypes";

const GeneralInfoItemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-between;
`;

const GeneralInfoItemStyled = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid rgb(219, 219, 219);
  flex-basis: 45%;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
`;

const GeneralInfoItemLabelStyled = styled.div`
  color: rgb(108, 108, 108);
`;
const GeneralInfoItemValueStyled = styled.div`
  color: rgb(46, 46, 46);
`;

const generalInfoItems: { label: string; localTranslate?: boolean; value: keyof CarItem }[] = [
  {
    label: "addCar.brand.label",
    value: "brand",
  },
  {
    label: "addCar.model.label",
    value: "modelView",
  },
  {
    label: "addCar.generation.label",
    value: "generationView",
  },
  {
    label: "addCar.modification.label",
    value: "modificationView",
  },
  {
    label: "addCar.year.label",
    value: "year",
  },
  {
    label: "addCar.color.label",
    value: "color",
    localTranslate: true,
  },
  {
    label: "addCar.gear.label",
    value: "gear",
    localTranslate: true,
  },
  {
    label: "addCar.fuel.label",
    value: "fuel",
    localTranslate: true,
  },
  {
    label: "addCar.bodyType.label",
    value: "bodyType",
    localTranslate: true,
  },
  {
    label: "addCar.driveType.label",
    value: "driveType",
    localTranslate: true,
  },
  {
    label: "addCar.mileage.label",
    value: "mileage",
  },
  {
    label: "addCar.power.label",
    value: "power",
  },
  {
    label: "addCar.engine.label",
    value: "engin",
  },
];

export const GeneralInfo: React.FC<{ car: PreviewCarItem }> = ({ car }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitleStyled>{t("preview.general")}</SectionTitleStyled>
      <GeneralInfoItemWrapperStyled>
        {generalInfoItems.map((item) => (
          <GeneralInfoItemStyled key={item.label}>
            <GeneralInfoItemLabelStyled>{t(item.label)}</GeneralInfoItemLabelStyled>

            <GeneralInfoItemValueStyled>
              {item.localTranslate
                ? t(`${car[item.value] as string}`)
                : (car[item.value] as string) || "-"}
            </GeneralInfoItemValueStyled>
          </GeneralInfoItemStyled>
        ))}
      </GeneralInfoItemWrapperStyled>
    </>
  );
};
