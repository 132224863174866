import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppSelector } from "../../../../helpers/store.hook";

const InfoWrapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  padding: 16px;
`;

const InfoItemStyled = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(219, 219, 219);
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const InfoIconStyled = styled.div`
  border-radius: 8px;
  background: rgba(116, 99, 232, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 60px;
`;

const InfoTextWrapperStyled = styled.div`
  margin-left: 16px;

  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ValueStyled = styled.div``;

const LabelStyled = styled.div`
  color: rgb(191, 191, 191);
  text-wrap: nowrap;
`;

export const CarInfo: React.FC<{ carCount: number; viewCount: number; likesCount: number }> = ({
  carCount = 0,
  viewCount = 0,
  likesCount = 0,
}) => {
  const { t } = useTranslation();
  const maxCarCount = useAppSelector((state) => state.auth.maxCarCount);
  return (
    <InfoWrapperStyled>
      <InfoItemStyled>
        <InfoIconStyled>
          <svg
            width="20.000000"
            height="16.000000"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M18.27 6.18L17.8 5L16.97 2.93C16.26 1.15 14.56 0 12.64 0L7.35 0C5.43 0 3.73 1.15 3.02 2.93L2.19 5L1.72 6.18C0.71 6.56 0 7.52 0 8.66L0 10.66C0 11.33 0.25 11.94 0.66 12.41L0.66 14.66C0.66 15.4 1.26 16 2 16L3.33 16C4.06 16 4.66 15.4 4.66 14.66L4.66 13.33L15.33 13.33L15.33 14.66C15.33 15.4 15.93 16 16.66 16L18 16C18.73 16 19.33 15.4 19.33 14.66L19.33 12.41C19.74 11.94 20 11.33 20 10.66L20 8.66C20 7.52 19.28 6.56 18.27 6.18ZM5.49 3.92C5.8 3.16 6.53 2.66 7.35 2.66L12.64 2.66C13.46 2.66 14.19 3.16 14.5 3.92L15.33 6L4.66 6L5.49 3.92ZM3.33 10.65C2.53 10.65 2 10.12 2 9.32C2 8.53 2.53 8 3.33 8C4.13 8 5.33 9.19 5.33 9.99C5.33 10.79 4.13 10.65 3.33 10.65ZM16.66 10.65C15.86 10.65 14.66 10.79 14.66 9.99C14.66 9.19 15.86 8 16.66 8C17.46 8 18 8.53 18 9.32C18 10.12 17.46 10.65 16.66 10.65Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </InfoIconStyled>
        <InfoTextWrapperStyled>
          <LabelStyled>{t("user.car.available-count")}</LabelStyled>
          <ValueStyled>
            {carCount}/{maxCarCount}
          </ValueStyled>
        </InfoTextWrapperStyled>
      </InfoItemStyled>
      <InfoItemStyled>
        <InfoIconStyled>
          <svg
            width="20.000000"
            height="20.000000"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M1.46 1.46C0 2.92 0 5.28 0 10C0 14.71 0 17.07 1.46 18.53C2.92 20 5.28 20 10 20C14.71 20 17.07 20 18.53 18.53C20 17.07 20 14.71 20 10C20 5.28 20 2.92 18.53 1.46C17.07 0 14.71 0 10 0C5.28 0 2.92 0 1.46 1.46ZM11.75 8C11.75 8.41 12.08 8.75 12.5 8.75L13.18 8.75L11.17 10.76C11.07 10.86 10.92 10.86 10.82 10.76L9.23 9.17C8.55 8.49 7.44 8.49 6.76 9.17L4.46 11.46C4.17 11.76 4.17 12.23 4.46 12.53C4.76 12.82 5.23 12.82 5.53 12.53L7.82 10.23C7.92 10.13 8.07 10.13 8.17 10.23L9.76 11.82C10.44 12.5 11.55 12.5 12.23 11.82L14.25 9.81L14.25 10.5C14.25 10.91 14.58 11.25 15 11.25C15.41 11.25 15.75 10.91 15.75 10.5L15.75 8C15.75 7.58 15.41 7.25 15 7.25L12.5 7.25C12.08 7.25 11.75 7.58 11.75 8Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </InfoIconStyled>
        <InfoTextWrapperStyled>
          <LabelStyled>{t("user.car.up-to-top")}</LabelStyled>
          <ValueStyled>{t("user.car.up-to-top-description")}</ValueStyled>
        </InfoTextWrapperStyled>
      </InfoItemStyled>
      <InfoItemStyled>
        <InfoIconStyled>
          <svg
            width="20.000000"
            height="16.000000"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M1.27 11.29C0.42 10.19 0 9.63 0 8C0 6.36 0.42 5.8 1.27 4.7C2.97 2.49 5.81 0 10 0C14.18 0 17.02 2.49 18.72 4.7C19.57 5.8 20 6.36 20 8C20 9.63 19.57 10.19 18.72 11.29C17.02 13.5 14.18 16 10 16C5.81 16 2.97 13.5 1.27 11.29ZM6.25 8C6.25 5.92 7.92 4.25 10 4.25C12.07 4.25 13.75 5.92 13.75 8C13.75 10.07 12.07 11.75 10 11.75C7.92 11.75 6.25 10.07 6.25 8ZM10 5.75C8.75 5.75 7.75 6.75 7.75 8C7.75 9.24 8.75 10.25 10 10.25C11.24 10.25 12.25 9.24 12.25 8C12.25 6.75 11.24 5.75 10 5.75Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </InfoIconStyled>
        <InfoTextWrapperStyled>
          <LabelStyled>{t("user.car.view-count-label")}</LabelStyled>
          <ValueStyled>{viewCount}</ValueStyled>
        </InfoTextWrapperStyled>
      </InfoItemStyled>
      <InfoItemStyled>
        <InfoIconStyled>
          <svg
            width="20.000000"
            height="17.215088"
            viewBox="0 0 20 17.2151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M0 5.85C0 10.71 4.01 13.3 6.96 15.62C8 16.44 9 17.21 10 17.21C11 17.21 12 16.44 13.03 15.62C15.98 13.3 20 10.71 20 5.85C20 0.98 14.49 -2.46 10 2.21C5.5 -2.46 0 0.98 0 5.85Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
        </InfoIconStyled>
        <InfoTextWrapperStyled>
          <LabelStyled>{t("user.car.like-label")}</LabelStyled>
          <ValueStyled>{likesCount}</ValueStyled>
        </InfoTextWrapperStyled>
      </InfoItemStyled>
    </InfoWrapperStyled>
  );
};
