import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { FormAddCar } from "../../../components/car/components/addCar/form";
import { MenuAddCar } from "../../../components/car/components/addCar/menu";
import {
  CarGetAdditionalInfoThunk,
  CarGetBrandsThunk,
  getCarByIdThunk,
} from "../../../components/car/redux/car.thunks";
import { useAppDispatch } from "../../../helpers/store.hook";

const WrapperStyled = styled.div`
  max-width: 1600px;
  margin: 20px auto;
  display: flex;
`;

interface AddCarPageProps {
  edit?: boolean;
  isAdmin?: boolean;
}

export const CarAdd: React.FC<AddCarPageProps> = ({ edit, isAdmin }) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(CarGetBrandsThunk());
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);

  useEffect(() => {
    if (params.id && edit) dispatch(getCarByIdThunk({ _id: params.id }));
  }, [dispatch, edit, params.id]);

  return (
    <WrapperStyled>
      <MenuAddCar />
      <FormAddCar edit={edit} />
    </WrapperStyled>
  );
};

// <RowStyled>
//         <RowTitleStyled>City</RowTitleStyled>
//         <CarInput onChange={handleString} type="city" />
//       </RowStyled>
//       <RowStyled>
//         <RowTitleStyled>Photos</RowTitleStyled>
//         <UploadImage />
//       </RowStyled>
//       <RowStyled>
//         <RowTitleStyled>Description</RowTitleStyled>
//         <TextAreaStyled
//           showCount
//           value={description?.value}
//           maxLength={1000}
//           placeholder="Disable resize"
//           onChange={handleDescription}
//           style={{ resize: "inherit", marginBottom: "10px" }}
//         />
//       </RowStyled>
//       <RowStyled>
//         <RowTitleStyled>Price</RowTitleStyled>
//         <CarInputNumber onChange={handleNumber} type="price" />
//       </RowStyled>

//       {/* <RowStyled>
//         <RowTitleStyled>Fuel consumption</RowTitleStyled>
//         <InputStyled controls={false} />
//       </RowStyled> */}

//       <div>
//         Additional Information
//         <Collapse
//           collapsible="header"
//           ghost
//           items={[
//             {
//               key: "1",
//               label: AdditionalInfoKeys.OPTICS,
//               children: (
//                 <>
//                   <div>
//                     <RowTitleStyled>Фари</RowTitleStyled>
//                     {/* <CarSelectAdditional
//                       placeholder="Headlights"
//                       optionsKey="headlight"
//                       type={AdditionalIonfoKeys.optic}
//                     /> */}
//                   </div>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.optic} />
//                 </>
//               ),
//             },
//             {
//               key: "2",
//               label: AdditionalInfoKeys.SALON,
//               children: (
//                 <>
//                   <div>
//                     <RowTitleStyled>Матеріал</RowTitleStyled>
//                     {/* <CarSelectAdditional
//                       placeholder="Salon Material"
//                       optionsKey={AdditionalInfoDTOKeys.SALON_MATERIAL}
//                       type={AdditionalIonfoKeys.salon}
//                     /> */}
//                   </div>
//                   <div>
//                     <RowTitleStyled>Колір салону</RowTitleStyled>
//                     {/* <CarSelectAdditional
//                       placeholder="Salon Color"
//                       optionsKey="salonColor"
//                       type={AdditionalIonfoKeys.salon}
//                     /> */}
//                   </div>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.salon} />
//                 </>
//               ),
//             },
//             {
//               key: "3",
//               label: AdditionalInfoKeys.SECURITY,
//               children: (
//                 <>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.security} />
//                 </>
//               ),
//             },
//             {
//               key: "4",
//               label: AdditionalInfoKeys.AIRBAGS,
//               children: (
//                 <>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.airbags} />
//                 </>
//               ),
//             },
//             {
//               key: "5",
//               label: AdditionalInfoKeys.MULTIMEDIA,
//               children: (
//                 <>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.multimedia} />
//                 </>
//               ),
//             },
//             {
//               key: "6",
//               label: AdditionalInfoKeys.OTHER,
//               children: (
//                 <>
//                   <CarChecboxGroup name={AdditionalIonfoKeys.other} />
//                 </>
//               ),
//             },
//           ]}
//         />
//       </div>
//       <Button onClick={handleSubmit}>{edit ? "Редагувати" : "Додати"}</Button>
