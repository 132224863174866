import { Button } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";

import { manageFavorite } from "../../../components/auth/redux/auth.thunks";
import { AdditionalInfoPyaload, CarItem } from "../../../components/car/CarTypes";
// import { CarSlider } from "../../../components/car/components/carSlider";
import { AdditionalInfo } from "../../../components/car/components/preview/AdditionalInfo";
import { CaRBreadcrumbs } from "../../../components/car/components/preview/Breadcrumbs";
import { Contacts } from "../../../components/car/components/preview/Contacts";
import { GeneralInfo } from "../../../components/car/components/preview/GeneralInfo";
import { InfoItems } from "../../../components/car/components/preview/InfoItems";
import { Recommendation } from "../../../components/car/components/preview/Recommendation";
import { AdditionalIonfoKeys } from "../../../components/car/constant";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";

export interface PreviewCarItem extends Omit<CarItem, "additionalOptions"> {
  additionalOptions: { [key in AdditionalIonfoKeys]: AdditionalInfoPyaload[] };
  user: { name: string; phone: string; userPhoto?: string };
}

const CarPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
  gap: 32px;
`;

const CarPageContainerStyled = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;
const TitleStyled = styled.h1`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 35px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
`;

const TitleWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleActionsWrapperStyled = styled.div``;

const ActiveStyle = css`
  border-color: var(--main-color) !important;
  background-color: var(--main-color) !important;

  & svg * {
    fill: white;
  }

  &:hover {
    border-color: rgb(219, 219, 219) !important;
    background-color: red !important;

    & svg * {
      fill: black;
    }
  }
`;

const InActiveStyle = css`
  &:hover {
    border-color: var(--main-color) !important;
    background-color: var(--main-color) !important;

    & svg * {
      fill: white;
    }
  }
`;

const FavoriteButtonStyled = styled(Button)<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;

  ${(props) => (props.$active ? ActiveStyle : InActiveStyle)}
`;

const CarWrapperStyled = styled.div`
  flex-grow: 1;
`;

const ContantWrapperStyled = styled.div`
  flex-grow: 1;
`;

const SubtitleStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  margin-bottom: 24px;
`;

const AdvertInfoStyled = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
`;

const AdvertInfoItemStyled = styled.div`
  display: flex;
  color: rgb(108, 108, 108);
  gap: 8px;
`;
const AdvertInfoItemValueStyled = styled.div`
  color: rgb(46, 46, 46);
`;

const DescriptionWrapperStyled = styled.div`
  margin-top: 40px;
`;

const DescriptionStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0;
`;

export const SectionTitleStyled = styled.h3`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  margin-bottom: 24px;
`;

const DetailsActionStyled = styled.div<{ $active?: boolean }>`
  padding: 12px;
  color: ${(props) => (props.$active ? "var(--main-color)" : "rgb(108, 108, 108);")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  border: 1px solid ${(props) => (props.$active ? "var(--main-color)" : "rgb(219, 219, 219)")};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
`;

const DetailsWrapperStyled = styled.div``;
const DetailsSwitcherWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  margin-top: 80px;
`;

export const CarPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const favorite = useAppSelector((state) => state.auth.favorite);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const [car, setCar] = useState<PreviewCarItem | null>(null);
  const [detail, setDetail] = useState("general");
  useEffect(() => {
    if (params.id) {
      axios
        .get(`/car/${params.id}`)
        .then((res) => res.data)
        .then((data) => setCar(data));

      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(params.id as string));

      if (cookieValue) {
        return;
      }
      document.cookie = `${params.id}=1; SameSite=None; Secure; max-age=${60 * 60 * 24 * 5}`;
      axios.post(`/car/update-view/${params.id}`);
    }
  }, [params.id]);

  const handleFaivorite = () => {
    if (params.id && isLogin) {
      const isNew = !favorite.some((item) => item === params?.id);

      dispatch(manageFavorite({ id: params.id, isNew }));
    }
  };

  const handleChangeDetails = (value: string) => () => {
    setDetail(value);
  };

  return (
    <>
      {car && (
        <CarPageContainerStyled>
          <CaRBreadcrumbs
            brand={car.brand}
            model={car.modelView}
            brandSearchName={car.brand}
            modelSearchName={car.model}
          />
          <CarPageWrapper>
            <CarWrapperStyled>
              <TitleWrapperStyled>
                <TitleStyled>
                  {car.brand.toUpperCase()} {car.modelView.toUpperCase()}{" "}
                  {car.modificationView.toUpperCase()} ({car.year})
                </TitleStyled>
                {isLogin && (
                  <TitleActionsWrapperStyled>
                    <FavoriteButtonStyled
                      onClick={handleFaivorite}
                      $active={!!params.id && favorite?.includes(params.id)}
                    >
                      <svg
                        width="19.000000"
                        height="17.176758"
                        viewBox="0 0 19 17.1768"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id="favorite"
                          d="M9.5 17.17L8.39 16.18C6.73 14.68 5.36 13.38 4.28 12.3C3.19 11.22 2.34 10.26 1.7 9.42C1.07 8.57 0.62 7.81 0.37 7.11C0.12 6.42 0 5.71 0 5C0 3.58 0.47 2.39 1.43 1.43C2.39 0.47 3.58 0 5 0C5.87 0 6.69 0.2 7.47 0.61C8.25 1.02 8.92 1.6 9.5 2.36C10.07 1.6 10.74 1.02 11.52 0.61C12.3 0.2 13.12 0 14 0C15.41 0 16.6 0.47 17.56 1.43C18.52 2.39 19 3.58 19 5C19 5.71 18.87 6.42 18.62 7.11C18.37 7.81 17.92 8.57 17.29 9.42C16.65 10.26 15.8 11.22 14.72 12.3C13.64 13.38 12.26 14.68 10.6 16.18L9.5 17.17Z"
                          fill="#1C1B1F"
                          fillOpacity="1.000000"
                          fillRule="nonzero"
                        />
                      </svg>
                    </FavoriteButtonStyled>
                  </TitleActionsWrapperStyled>
                )}
              </TitleWrapperStyled>
              <SubtitleStyled>
                {car.generationView} {car.modificationView}
              </SubtitleStyled>
              <InfoItems car={car} />
              <div>Slider</div>
              <AdvertInfoStyled>
                <AdvertInfoItemStyled>
                  {t("preview.published")}:
                  <AdvertInfoItemValueStyled>
                    {new Date(car.createdAt).toLocaleDateString()}
                  </AdvertInfoItemValueStyled>
                  | {t("preview.views")}:
                  <AdvertInfoItemValueStyled>{car.views}</AdvertInfoItemValueStyled>|{" "}
                  {t("preview.saved")}:
                  <AdvertInfoItemValueStyled>{car.likes}</AdvertInfoItemValueStyled>
                </AdvertInfoItemStyled>
                <AdvertInfoItemStyled>
                  {t("preview.advert-id")}:
                  <AdvertInfoItemValueStyled>{car._id}</AdvertInfoItemValueStyled>
                </AdvertInfoItemStyled>
              </AdvertInfoStyled>
              <DescriptionWrapperStyled>
                <SectionTitleStyled>{t("preview.description")}</SectionTitleStyled>
                <DescriptionStyled>{car.description}</DescriptionStyled>
              </DescriptionWrapperStyled>
              <DetailsWrapperStyled>
                <DetailsSwitcherWrapperStyled>
                  <DetailsActionStyled
                    $active={detail === "general"}
                    onClick={handleChangeDetails("general")}
                  >
                    {t("preview.general")}
                  </DetailsActionStyled>
                  <DetailsActionStyled
                    $active={detail === "additional"}
                    onClick={handleChangeDetails("additional")}
                  >
                    {t("preview.additional")}
                  </DetailsActionStyled>
                </DetailsSwitcherWrapperStyled>

                {detail === "general" ? <GeneralInfo car={car} /> : <AdditionalInfo car={car} />}
              </DetailsWrapperStyled>
            </CarWrapperStyled>
            <ContantWrapperStyled>
              <Contacts car={car} />
            </ContantWrapperStyled>
          </CarPageWrapper>
          <Recommendation carId={car._id} />
        </CarPageContainerStyled>
      )}
    </>
  );
};
