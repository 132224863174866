import { Breadcrumb } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { FiltersActions } from "../../../filters/redux/filters.slice";

const ItemStyled = styled.div`
  color: rgb(52, 51, 57);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
`;

export const BreadCrumb = () => {
  const dispatch = useAppDispatch();
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData.brand);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);

  const items = useMemo(() => {
    const res: {
      title: JSX.Element;
      onClick?: () => void;
      className?: string;
    }[] = [
      {
        title: <ItemStyled>Home</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearBrand()),
        className: "pointer",
      },
    ];
    if (selectedBrand) {
      res.push({
        title: <ItemStyled>{selectedBrand?.name}</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearModel()),
        className: "pointer",
      });
    }
    if (selectedModel) {
      res.push({
        title: <ItemStyled>{selectedModel?.name}</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearGenerations()),
        className: "pointer",
      });
    }
    if ((selectedGenerations?.length ?? 0) > 0) {
      res.push({
        title: <ItemStyled>{selectedGenerations?.map((gen) => gen.label).join(", ")}</ItemStyled>,
      });
    }
    res[res.length - 1].onClick = () => {
      return;
    };
    res[res.length - 1].className = "normal";
    return res;
  }, [selectedModel, selectedBrand, selectedGenerations, dispatch]);

  return (
    <>
      <Breadcrumb separator={<ItemStyled>/</ItemStyled>} items={items} />
    </>
  );
};
