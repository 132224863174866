import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemProps, FormItemStyled, LabelStyled } from "./form";
import { CITY } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { InputStyled } from "../../shared/Input";

export const CityAddCar: React.FC<FormItemProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const city = useAppSelector((state) => state.car.selectedData?.city);
  useEffect(() => {
    form.setFieldsValue({ city });
  }, [city, form]);
  const handleChange = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    if (e) {
      dispatch(CarActions.setValue({ key: CITY, value: e.target.value }));
    }
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.city.label")}</LabelStyled>}
      name={CITY}
      rules={[{ required: false, message: t("addCar.city.error") }]}
    >
      <InputStyled onChange={handleChange} placeholder={t("addCar.city.placeholder")} />
    </FormItemStyled>
  );
};
