import { Checkbox, Tooltip } from "antd";
import { useCallback, useEffect } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormItemProps, FormItemStyled, LabelStyled } from "./form";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { icons } from "../../../common/icons";
import { CarState, SelectedDataStringKeys } from "../../CarTypes";
import { AdditionalIonfoKeys } from "../../constant";
import { CarActions } from "../../redux/car.slice";
import { SelectStyled } from "../../shared/Input";

export interface OnSelectProps {
  type: SelectedDataStringKeys;
  value?: string;
  options: { searchName: string };
}

export const SimpleSelectAddCar: React.FC<
  {
    optionsKey: keyof Omit<CarState, "selectedData" | "currentVisibleMenu" | "inProgress">;
    type: SelectedDataStringKeys | "year";
    required?: boolean;
  } & FormItemProps
> = React.memo(({ type, optionsKey, required = true, form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car[optionsKey]);
  const value = useAppSelector((state) => state.car.selectedData[type]);

  useEffect(() => {
    form.setFieldsValue({ [type]: value });
  }, [type, value, form]);

  const handleString = useCallback(
    (value: string, option: { searchName: string }) => {
      if (type === "year") {
        return dispatch(CarActions.setNumberValue({ key: type, value: Number(option.searchName) }));
      }
      dispatch(CarActions.setValue({ key: type, value: option.searchName }));
    },
    [dispatch, type],
  );

  const handleClear = () => {
    dispatch(CarActions.clearValue({ key: type }));
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t(`addCar.${type}.label`)}</LabelStyled>}
      name={type}
      rules={[{ required, message: t(`addCar.${type}.error`) }]}
    >
      <SelectStyled
        placeholder={t(`addCar.${type}.placeholder`)}
        options={options}
        showSearch
        allowClear
        onSelect={handleString}
        onClear={handleClear}
        value={value}
        filterOption={(input, option) => {
          if (option?.options) return false;

          return String(option?.label).toLowerCase().includes(String(input).toLowerCase());
        }}
      />
    </FormItemStyled>
  );
});

export const SimpleSelectAdditionalInfoAddCar: React.FC<{
  optionsKey: keyof Omit<CarState, "selectedData" | "currentVisibleMenu" | "inProgress">;
  type: AdditionalIonfoKeys;
  required?: boolean;
}> = React.memo(({ type, optionsKey, required = true }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car[optionsKey]);
  const value = useAppSelector((state) => state.car.selectedData.additionalOptions[type]);

  const handleString = useCallback(
    (value: string, option: { searchName: string }) => {
      dispatch(
        CarActions.setSelectedAdditionalOptionsSelect({ name: type, option: option.searchName }),
      );
    },
    [dispatch, type],
  );

  const handleClear = () => {
    dispatch(CarActions.clearSelectedAdditionalOptionsSelect({ name: type }));
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t(`addCar.${type}.label`)}</LabelStyled>}
      name={type}
      rules={[{ required, message: t(`addCar.${type}.error`) }]}
    >
      <SelectStyled
        placeholder={t(`addCar.${type}.placeholder`)}
        options={options}
        showSearch
        allowClear
        onSelect={handleString}
        onClear={handleClear}
        value={value}
        filterOption={(input, option) => {
          if (option?.options) return false;

          return String(option?.label).toLowerCase().includes(String(input).toLowerCase());
        }}
      />
    </FormItemStyled>
  );
});

const CheckboxWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ImageStyled = styled.img`
  width: 150px;
  height: auto;
`;

export const CheckBoxAdditionalInfoAddCar: React.FC<{ name: AdditionalIonfoKeys }> = React.memo(
  ({ name }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const options = useAppSelector((state) => state.car[name]);
    const selectedOptions = useAppSelector(
      (state) => state.car.selectedData.additionalOptions[name],
    );
    const handleOption = (option: string) => () => {
      dispatch(CarActions.setSelectedAdditionalOptions({ name, option }));
    };

    return (
      <Checkbox.Group value={selectedOptions}>
        {options.map((option) => (
          <CheckboxWrapperStyled key={option._id}>
            <Checkbox onChange={handleOption(option.searchName)} value={option.searchName}>
              {t(option.searchName)}
            </Checkbox>
            <Tooltip title={<ImageStyled src={icons[option.searchName]} alt={option.searchName} />}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc>Created with Pixso.</desc>
                <defs />
                <mask
                  id="mask24_1753"
                  maskUnits="userSpaceOnUse"
                  x="0.000000"
                  y="0.000000"
                  width="24.000000"
                  height="24.000000"
                >
                  <rect
                    id="Bounding box"
                    width="24.000000"
                    height="24.000000"
                    fill="#D9D9D9"
                    fillOpacity="1.000000"
                  />
                </mask>
                <g mask="url(#mask24_1753)">
                  <path
                    id="info"
                    d="M11.5 16.5L12.5 16.5L12.5 11L11.5 11L11.5 16.5ZM12 9.57C12.17 9.57 12.32 9.51 12.43 9.39C12.55 9.28 12.61 9.13 12.61 8.96C12.61 8.78 12.55 8.64 12.43 8.52C12.32 8.4 12.17 8.34 12 8.34C11.82 8.34 11.67 8.4 11.56 8.52C11.44 8.64 11.38 8.78 11.38 8.96C11.38 9.13 11.44 9.28 11.56 9.39C11.67 9.51 11.82 9.57 12 9.57ZM12 21C10.75 21 9.58 20.76 8.49 20.29C7.39 19.81 6.44 19.17 5.63 18.36C4.82 17.55 4.18 16.6 3.7 15.51C3.23 14.41 3 13.24 3 12C3 10.75 3.23 9.58 3.7 8.49C4.18 7.39 4.82 6.44 5.63 5.63C6.44 4.82 7.39 4.18 8.48 3.7C9.58 3.23 10.75 3 11.99 3C13.24 3 14.41 3.23 15.5 3.7C16.6 4.18 17.55 4.82 18.36 5.63C19.17 6.44 19.81 7.39 20.29 8.48C20.76 9.58 21 10.75 21 11.99C21 13.24 20.76 14.41 20.29 15.5C19.81 16.6 19.17 17.55 18.36 18.36C17.55 19.17 16.6 19.81 15.51 20.29C14.41 20.76 13.24 21 12 21Z"
                    fill="#DBDBDB"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </Tooltip>
          </CheckboxWrapperStyled>
        ))}
      </Checkbox.Group>
    );
  },
);
