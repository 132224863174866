import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import axios from "../../../../helpers/interseptor";
import { CarRoutesPath } from "../../../../routes";
import { CarItem } from "../../../car/CarTypes";
import { SearchCart } from "../../../search/components/searchCart";

const WrapperStyled = styled.div`
  margin-top: 120px;
`;

const TitleStyled = styled.h4`
  /* 48 */
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0;
`;

const ContainerStyled = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 15px;
`;

export const ResentCar = () => {
  const [cars, setCar] = useState<CarItem[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get<CarItem[]>("search/recent-car")
      .then((res) => res.data)
      .then((data) => {
        setCar(data);
      });
  }, []);
  return (
    <WrapperStyled>
      <TitleStyled>{t("main.recent.title")}</TitleStyled>
      <ContainerStyled>
        {cars.map((car) => (
          <SearchCart car={car} key={car._id} link={`${CarRoutesPath.car}/${car._id}`} />
        ))}
      </ContainerStyled>
    </WrapperStyled>
  );
};
