import { FormInstance } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { MODEL } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { CarSelectGenerationsThunk } from "../../redux/car.thunks";
import { SelectStyled } from "../../shared/Input";

export const ModelAddCar: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car.models);
  const model = useAppSelector((state) => state.car.selectedData.model);

  const handleModel = (
    value: string,
    option: { searchName: string; parentModel?: string; name: string },
  ) => {
    if (value)
      dispatch(
        CarSelectGenerationsThunk({
          value,
          searchName: option.searchName,
          parentModel: option.parentModel,
          name: option.name,
        }),
      );
  };

  useEffect(() => {
    form.setFieldsValue({ model });
  }, [model, form]);

  const handleClear = () => {
    dispatch(CarActions.clearModel());
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.model.label")}</LabelStyled>}
      name={MODEL}
      rules={[{ required: true, message: t("addCar.model.error") }]}
      validateTrigger="onChange"
    >
      <SelectStyled
        placeholder={t("addCar.model.placeholder")}
        options={options}
        showSearch
        allowClear
        onSelect={handleModel}
        onClear={handleClear}
      />
    </FormItemStyled>
  );
};
