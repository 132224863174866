import { Button, Form, FormInstance } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AfterAccidentAddCar } from "./afterAccident";
import { AirBagAddCar } from "./airbag";
import { BrandAddCar } from "./brand";
import { CityAddCar } from "./city";
import { DescriptionAddCar } from "./description";
import { GenerationAddCar } from "./generation";
import { HideVinAddCar } from "./hideVin";
import { ModelAddCar } from "./model";
import { ModificationAddCar } from "./modification";
import { MultimediaAddCar } from "./multimedia";
import { NumberAddCar } from "./number";
import { OpticsAddCar } from "./optics";
import { OtherAddCar } from "./other";
import { PhoneAddCar } from "./phone";
import { PhotoAddCar } from "./photo";
import { PriceAddCar } from "./price";
import { SalonAddCar } from "./salon";
import { SecurityAddCar } from "./security";
import { SimpleInputNumberAddCar } from "./simpleInputNumber";
import { SimpleSelectAddCar } from "./simpleSelect";
import { UseInfoAddCar } from "./useInfo";
import { VinAddCar } from "./vin";
import {
  BODY_TYPE,
  COLOR,
  DRIVE_TYPE,
  ENGINE,
  FUEL,
  GEAR,
  MILEAGE,
  POWER,
  REGION,
  YEAR,
} from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { MenuID } from "../../constant";
import { CarSubmitThunk } from "../../redux/car.thunks";
import { LinkWrapper } from "../../shared/LinkWrapper";

export const FormItemStyled = styled(Form.Item)``;

export const LabelStyled = styled.span`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const FormItemRowStyled = styled.div`
  display: flex;
  gap: 32px;
  & > div {
    flex: 1;
  }
`;

const FormWreapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 16px;
  max-width: 1600px;
  padding: 32px;
  margin-bottom: 16px;
`;

const FormItemColumnStyled = styled.div``;

const TitleStyled = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 175%;
`;

const SubtitleStyled = styled.h3`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
  margin: 16px 0;
`;

const FormStyled = styled(Form)`
  position: relative;
  margin-bottom: 70px;
`;

const ButtonWrapperStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgb(219, 219, 219);
  background: rgb(250, 250, 250);
  padding: 16px 0;
  z-index: 100;
`;

const ButtonStyled = styled(Button)`
  background: var(--main-color);
  width: 100%;
  max-width: 1600px;
  height: 50px;
  color: rgb(250, 250, 250);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

export interface FormItemProps {
  form: FormInstance;
}

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
  <>
    {label}
    {required && <span style={{ marginLeft: "2px" }}> *</span>}
  </>
);
export const FormAddCar: React.FC<{ edit?: boolean }> = ({ edit }) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("+380");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const inProgress = useAppSelector((state) => state.car.inProgress);
  const [isPhoneValid, setIsPhoneValid] = useState(isLogin);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (!isLogin && !isPhoneValid) {
      console.log("phone is not valid");

      return;
    }
    const payload: {
      edit?: boolean;
      _id?: string;
      isAdmin?: boolean;
      phone?: string;
      navigate: NavigateFunction;
    } = { navigate };
    if (!isLogin) {
      payload.phone = phone;
    }
    if (edit) {
      payload.edit = edit;
    }
    dispatch(CarSubmitThunk(payload));
    // console.log("Received values of form: ", values);
  };

  return (
    <FormStyled
      form={form}
      layout="vertical"
      requiredMark={customizeRequiredMark}
      onFinish={onFinish}
      scrollToFirstError
      validateTrigger="onChange"
      autoComplete="off"
    >
      <LinkWrapper id={MenuID.GENERAL}>
        <TitleStyled>{t("addCar.title.mainInfo")}</TitleStyled>
        <FormWreapperStyled>
          {isLogin ? null : (
            <PhoneAddCar
              setIsPhoneValid={setIsPhoneValid}
              form={form}
              phone={phone}
              setPhone={setPhone}
            />
          )}
          <FormItemRowStyled>
            <FormItemColumnStyled>
              <UseInfoAddCar form={form} />
              <AfterAccidentAddCar form={form} />
            </FormItemColumnStyled>
            <FormItemColumnStyled>
              <VinAddCar form={form} />
              <HideVinAddCar form={form} />
            </FormItemColumnStyled>
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleSelectAddCar form={form} optionsKey={REGION} type={REGION} />
            <CityAddCar form={form} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <BrandAddCar form={form} />
            <ModelAddCar form={form} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <GenerationAddCar form={form} />
            <ModificationAddCar form={form} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleSelectAddCar form={form} optionsKey={YEAR} type={YEAR} />
            <SimpleSelectAddCar form={form} optionsKey={COLOR} type={COLOR} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleSelectAddCar form={form} optionsKey={GEAR} type={GEAR} />
            <SimpleSelectAddCar form={form} optionsKey={FUEL} type={FUEL} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleSelectAddCar form={form} optionsKey={BODY_TYPE} type={BODY_TYPE} />
            <SimpleSelectAddCar form={form} optionsKey={DRIVE_TYPE} type={DRIVE_TYPE} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleInputNumberAddCar form={form} type={MILEAGE} min={1} max={2000000} />
            <NumberAddCar form={form} />
          </FormItemRowStyled>
          <FormItemRowStyled>
            <SimpleInputNumberAddCar form={form} type={POWER} min={1} max={5000} />
            <SimpleSelectAddCar form={form} optionsKey={ENGINE} type={ENGINE} required={false} />
          </FormItemRowStyled>

          <DescriptionAddCar form={form} />
          <PriceAddCar form={form} />
        </FormWreapperStyled>
      </LinkWrapper>
      <LinkWrapper id={MenuID.PHOTO}>
        <TitleStyled>{t("addCar.title.photo")}</TitleStyled>
        <FormWreapperStyled>
          <PhotoAddCar form={form} />
        </FormWreapperStyled>
      </LinkWrapper>
      <LinkWrapper id={MenuID.ADDITIONAL}>
        <TitleStyled>{t("addCar.title.additionalInfo")}</TitleStyled>
        <SubtitleStyled>{t("addCar.title.optic")}</SubtitleStyled>
        <FormWreapperStyled>
          <OpticsAddCar />
        </FormWreapperStyled>
        <SubtitleStyled>{t("addCar.title.salon")}</SubtitleStyled>
        <FormWreapperStyled>
          <SalonAddCar />
        </FormWreapperStyled>
        <SubtitleStyled>{t("addCar.title.security")}</SubtitleStyled>
        <FormWreapperStyled>
          <SecurityAddCar />
        </FormWreapperStyled>
        <SubtitleStyled>{t("addCar.title.airbag")}</SubtitleStyled>
        <FormWreapperStyled>
          <AirBagAddCar />
        </FormWreapperStyled>
        <SubtitleStyled>{t("addCar.title.multimedia")}</SubtitleStyled>
        <FormWreapperStyled>
          <MultimediaAddCar />
        </FormWreapperStyled>
        <SubtitleStyled>{t("addCar.title.other")}</SubtitleStyled>
        <FormWreapperStyled>
          <OtherAddCar />
        </FormWreapperStyled>
      </LinkWrapper>
      <ButtonWrapperStyled>
        <ButtonStyled type="primary" htmlType="submit" disabled={inProgress}>
          {edit ? t("addCar.button.edit") : t("addCar.button.addCar")}
        </ButtonStyled>
      </ButtonWrapperStyled>
    </FormStyled>
  );
};
