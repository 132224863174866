import { Button } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { CarRoutesPath } from "../../../../routes";
import { CarItem } from "../../../car/CarTypes";
import { SearchCart } from "../../../search/components/searchCart";

const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

const FooterWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;

const ActionCss = css`
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  height: auto;
  &:hover {
    color: rgb(250, 250, 250) !important;
    background: var(--main-color) !important;
    border-color: var(--main-color) !important;
  }
`;

const EditButton = styled(Link)`
  ${ActionCss}
`;
const DeleteButton = styled(Button)<{ $disabled?: boolean }>`
  ${ActionCss}
  ${(props) => props.$disabled && "opacity: 0.5; pointer-events: none;"}
`;

const Footer = ({
  _id,
  isActive,
  onDelete,
  upToTop,
  onActive,
  lastUpTopDate,
}: {
  _id: string;
  isActive: boolean;
  onDelete: (_id: string) => void;
  upToTop: (_id: string) => void;
  onActive: (_id: string) => void;
  lastUpTopDate: string;
}) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    onDelete(_id);
  };

  const handleUpToTop = () => {
    upToTop(_id);
  };
  const handleActive = () => {
    onActive(_id);
  };

  const isUpDisabled = useMemo(() => {
    const currentDate = new Date();

    const threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 3));

    if (new Date(lastUpTopDate) >= threeDaysAgo) {
      return true;
    }
    return false;
  }, [lastUpTopDate]);

  return (
    <FooterWrapperStyled>
      <EditButton to={`${CarRoutesPath.car}${CarRoutesPath.edit}/${_id}`}>
        {t("card.edit")}
      </EditButton>
      <DeleteButton onClick={handleActive}>
        {isActive ? t("card.deactivate") : t("card.activate")}
      </DeleteButton>
      <DeleteButton onClick={handleUpToTop} $disabled={isUpDisabled}>
        {t("card.to-top")}
      </DeleteButton>
      <DeleteButton onClick={handleDelete}>{t("card.delete")}</DeleteButton>
    </FooterWrapperStyled>
  );
};

export const UserCarsList: React.FC<{
  cars: CarItem[];
  onDelete: (id: string) => void;
  onUpToTop: (id: string) => void;
  onActive: (id: string) => void;
}> = ({ cars, onDelete, onUpToTop, onActive }) => {
  return (
    <ListStyled>
      {cars.map((car) => (
        <SearchCart
          car={car}
          key={car._id}
          link={`${CarRoutesPath.car}/${car._id}`}
          footer={
            <Footer
              _id={car._id}
              onDelete={onDelete}
              upToTop={onUpToTop}
              isActive={car.isActive}
              onActive={onActive}
              lastUpTopDate={car.lastUpTopDate}
            />
          }
        />
      ))}
    </ListStyled>
  );
};
