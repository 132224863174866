import { BreadCrumb } from "./breadCrumb";
import { CardSearch } from "./cardSearch";

export const CardSelect = () => {
  return (
    <div>
      <BreadCrumb />
      <CardSearch />
    </div>
  );
};
