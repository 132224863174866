import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DRIVE_TYPE, FUEL, GEAR, MILEAGE } from "../../../../constants/AdditionalInfo";
import { PreviewCarItem } from "../../../../pages/car/preview/PreviewCar";
import DriveType from "../../../search/asset/driveType.svg";
import FuelIcon from "../../../search/asset/fuel.svg";
import GearIcon from "../../../search/asset/gear.svg";
import MileageIcon from "../../../search/asset/mileage.svg";

const CarInfoStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;

const InfoItem = styled.div`
  border-radius: 4px;
  padding: 8px;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
`;

const InfoItemIcon = styled.div`
  margin-right: 8px;
  display: flex;
  & img {
    width: 17px;
  }
`;

const InfoItemText = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  margin-top: 1px;
`;

const infoItems: [typeof GEAR, typeof MILEAGE, typeof DRIVE_TYPE, typeof FUEL] = [
  GEAR,
  MILEAGE,
  DRIVE_TYPE,
  FUEL,
];

const infoIcons: Record<typeof GEAR | typeof MILEAGE | typeof DRIVE_TYPE | typeof FUEL, string> = {
  [GEAR]: GearIcon,
  [MILEAGE]: MileageIcon,
  [DRIVE_TYPE]: DriveType,
  [FUEL]: FuelIcon,
};

export const InfoItems: React.FC<{ car: PreviewCarItem }> = ({ car }) => {
  const { t } = useTranslation();
  return (
    <CarInfoStyled>
      {infoItems.map((item) => (
        <InfoItem key={item}>
          <InfoItemIcon>
            <img src={infoIcons[item]} alt={item} />
          </InfoItemIcon>
          <InfoItemText>
            {item === MILEAGE
              ? `${Number(car.mileage)?.toLocaleString()} ${t("km")}`
              : t(car[item])}
          </InfoItemText>
        </InfoItem>
      ))}
    </CarInfoStyled>
  );
};
