import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { SearchRoutesPath } from "../../../../routes";

const BreadcrumbWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbItemStyled = styled(NavLink)`
  color: rgb(52, 51, 57);
  text-decoration: none;

  font-family: "Source Sans 3";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;
const DeviderStyled = styled.span`
  margin: 0 8px;
`;

export const CaRBreadcrumbs: React.FC<{
  brand: string;
  model: string;
  brandSearchName: string;
  modelSearchName: string;
}> = ({ brand, model, brandSearchName, modelSearchName }) => {
  const { t } = useTranslation();

  return (
    <BreadcrumbWrapperStyled>
      <BreadcrumbItemStyled to="/">
        <svg
          width="15.000000"
          height="16"
          viewBox="0 0 15 16.8943"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <path
            id="home"
            d="M0 16.89L0 5.64L7.5 0L15 5.64L15 16.89L9.4 16.89L9.4 10.2L5.59 10.2L5.59 16.89L0 16.89Z"
            fill="#1C1B1F"
            fillOpacity="1.000000"
            fillRule="nonzero"
          />
        </svg>
        {t("breadcrumb.home")}
      </BreadcrumbItemStyled>
      <DeviderStyled>/</DeviderStyled>
      <BreadcrumbItemStyled to={`${SearchRoutesPath.search}?brand=${brandSearchName}`}>
        {brand}
      </BreadcrumbItemStyled>
      <DeviderStyled>/</DeviderStyled>
      <BreadcrumbItemStyled
        to={`${SearchRoutesPath.search}?brand=${brandSearchName}&model=${modelSearchName}`}
      >
        {model}
      </BreadcrumbItemStyled>
    </BreadcrumbWrapperStyled>
  );
};
