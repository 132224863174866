import { Button } from "antd";
import styled from "styled-components";

export enum BTNTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export const ButtonStyled = styled(Button)<{ $type: BTNTypes; $hidden?: boolean }>`
  visibility: ${({ $hidden }) => ($hidden ? "hidden" : "visible")};
  color: ${({ $type }) =>
    $type === BTNTypes.PRIMARY ? "rgb(255, 255, 255)" : "var(--main-color)"};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  height: 100%;
  border-color: var(--main-color) !important;

  background-color: ${({ $type }) =>
    $type === BTNTypes.PRIMARY ? "var(--main-color)" : "rgb(255, 255, 255)"};

  &:hover {
    color: rgb(255, 255, 255) !important;
    border-color: var(--main-color) !important;
    background-color: rgb(63 18 255) !important;
  }
`;
