import { BrandDTO, CarModelDTO, GenerationsDTO } from "./search.dto";

export const searchSteps = ["brand", "model", "generation", "modification"];

export enum SearchEnum {
  BRAND = "Brand",
  MODEL = "Model",
  GENERATION = "Generation",
  MODIFICATION = "Modification",
}

export interface Brands extends Omit<BrandDTO, "name" | "image"> {
  label: string;
  value: string;
  image?: string;
}

export interface CarModel extends Omit<CarModelDTO, "generations" | "series" | "isSeries"> {
  series?: Array<Omit<CarModel, "value" | "label">>;
  label: string;
  value?: string;
  title?: string;
  options?: Array<Omit<CarModel, "brand"> & { isParent?: boolean; parentId?: string }>;
}

export interface Generations extends GenerationsDTO {
  label: string;
  value: string;
}

export interface Icons {
  [key: string]: string;
}

export enum SearchHistory {
  history = "history",
}
