import { FormInstance } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { GENERATION } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { SelectStyled } from "../../shared/Input";

export const GenerationAddCar: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car.generations);
  const generation = useAppSelector((state) => state.car.selectedData.generation);

  const handleGeneration = (value: string, option: { searchName: string; name: string }) => {
    if (value)
      dispatch(
        CarActions.setGeneration({ value, searchName: option.searchName, name: option.name }),
      );
  };
  useEffect(() => {
    form.setFieldsValue({ generation });
  }, [generation, form]);

  const handleClear = () => {
    dispatch(CarActions.clearGeneration());
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.generation.label")}</LabelStyled>}
      name={GENERATION}
      rules={[{ required: true, message: t("addCar.generation.error") }]}
    >
      <SelectStyled
        placeholder={t("addCar.generation.placeholder")}
        options={options}
        showSearch
        allowClear
        onSelect={handleGeneration}
        onClear={handleClear}
      />
    </FormItemStyled>
  );
};
