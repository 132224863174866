import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormItemProps } from "./form";
import { VIN_HIDE } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";

const CheckboxStyled = styled(Checkbox)`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

export const HideVinAddCar: React.FC<FormItemProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const vinHide = useAppSelector((state) => state.car.selectedData?.vinHide);
  useEffect(() => {
    form.setFieldsValue({ vinHide });
  }, [vinHide, form]);

  const handleChange = (e: CheckboxChangeEvent) => {
    if (e) {
      dispatch(CarActions.setBoolean({ key: VIN_HIDE, value: e.target.checked }));
    }
  };
  return (
    <Form.Item name={VIN_HIDE} rules={[{ required: false }]} valuePropName="checked">
      <CheckboxStyled onChange={handleChange}>{t("addCar.vin.vinHide")}</CheckboxStyled>
    </Form.Item>
  );
};
