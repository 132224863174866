import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { v4 } from "uuid";

import axios from "../../../helpers/interseptor";
import i18next from "../../../i18n/config";
import { LocalStorageKeys, UserDTO, loginDTO } from "../types";
import { getToken, setTokenLocal, deleteTokenLocal } from "../utils/tokenLocal";

interface ValidationErrors {
  errorMessage: string;
}

export const ChangePasswordThunk = createAsyncThunk<
  loginDTO,
  {
    code: string;
    password: string;
    phone: string;
  },
  {
    rejectValue: ValidationErrors;
  }
>("changePasswordThunk", async ({ code, password, phone }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/change-forgot-password", {
      code,
      password,
      phone,
    });
    return response.data;
  } catch (e) {
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const VerifyPhoneThunk = createAsyncThunk<
  loginDTO,
  {
    code: string;
  },
  {
    rejectValue: ValidationErrors;
  }
>("verifyPhoneThunk", async ({ code }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/verify-phone", { code });

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const LoginThunk = createAsyncThunk<
  loginDTO,
  {
    userData: {
      name?: string;
      password: string;
      phone: string;
      remember: boolean;
    };
  },
  {
    rejectValue: ValidationErrors;
  }
>("loginThunk", async ({ userData }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/login", userData);
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    if (userData.remember) setTokenLocal(response.data.tokens);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const RegisterThunk = createAsyncThunk<
  loginDTO,
  {
    userData: {
      name?: string;
      phone: string;
      password: string;
    };
  },
  {
    rejectValue: Record<string, string>;
  }
>("registerThunk", async ({ userData }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/sign-in", userData);
    setTokenLocal(response.data.tokens);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue(e.response?.data);
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const ResetPasswordThunk = createAsyncThunk<
  loginDTO,
  {
    userData: {
      code?: string;
      phone: string;
      password: string;
    };
  },
  {
    rejectValue: Record<string, string>;
  }
>("resetPasswordThunk", async ({ userData }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/change-forgot-password", userData);
    setTokenLocal(response.data.tokens);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue(e.response?.data);
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const getCurrentUser = createAsyncThunk<
  UserDTO,
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("getCurrentUserThunk", async (args, { rejectWithValue }) => {
  if (getToken(LocalStorageKeys.JWT_ACCESS) === null) return rejectWithValue({ errorMessage: "" });
  try {
    const response = await axios.get<UserDTO>("auth/getUser");

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    deleteTokenLocal();
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }

    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const manageFavorite = createAsyncThunk<
  UserDTO,
  { id: string; isNew: boolean },
  { rejectValue: ValidationErrors }
>("addToFavorite", async ({ id, isNew }, { rejectWithValue }) => {
  try {
    let response;
    if (isNew) {
      response = await axios.post<UserDTO>(`user/favorite/${id}`);
    } else {
      response = await axios.delete<UserDTO>(`user/favorite/${id}`);
    }
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const updateSettings = createAsyncThunk<
  { name: string },
  { phone: string; password?: string; name: string },
  {
    rejectValue: ValidationErrors;
  }
>("updateSettingsThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.post<{ name: string }>("user/settings", args);

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    notification.success({ message: i18next.t("user.settings.save-success") });

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      notification.error({ message: i18next.t("user.settings.save-error") });
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }

    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const updateUserPhoto = createAsyncThunk<
  { photo: string; isPhotoApproved: boolean },
  { photo: string },
  {
    rejectValue: ValidationErrors;
  }
>("updateUserPhotoThunk", async ({ photo }, { rejectWithValue }) => {
  try {
    const payloadImage = new FormData();

    const blob = await fetch(photo).then((r) => r.blob());
    payloadImage.append("image", blob, `${v4()}`);

    const response = await axios.post<{ photo: string; isPhotoApproved: boolean }>(
      "user/upload-user-photo",
      payloadImage,
    );

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    notification.success({ message: i18next.t("user.settings.photo-success") });

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      notification.error({
        message: i18next.t("Something went wrong"),
      });
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }

    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});
