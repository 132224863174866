import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { SelectStyledMulty } from "../../searchFilter";

export const DriveTypeSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { manageAdvanceSearch } = useSearch();
  const driveTypes = useAppSelector((state) => state.car.driveType);
  const selectedDriveType = useAppSelector((state) => state.filters.selectedData?.driveType);

  const hangleDriveType = (value: string[]) => {
    dispatch(FiltersActions.setDriveTypes(value));
    manageAdvanceSearch("driveType", value);
  };
  return (
    <SelectStyledMulty
      options={driveTypes}
      value={selectedDriveType}
      // onSelect={handleModel}
      onChange={hangleDriveType}
      // onClear={handleClear}
      placeholder={t("addCar.driveType.label")}
      mode="multiple"
      showSearch
      allowClear
      size="large"
    />
  );
};
