import { InputNumber } from "antd";
import styled, { css } from "styled-components";

const InputNumberStyle = css`
  padding: 10px 11px;
  width: 100%;
  &:hover {
    border-color: var(--main-color) !important;
  }

  &:focus {
    border-color: var(--main-color) !important;
    box-shadow: 0 0 0 2px rgb(80 5 255 / 10%) !important;
  }

  &:focus-within {
    border-color: var(--main-color) !important;
    box-shadow: 0 0 0 2px rgb(80 5 255 / 10%) !important;
  }

  & .ant-input-number-prefix {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: rgb(191, 191, 191);
  }

  & .ant-input-number-input {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;

    &:placeholder {
      color: rgb(191, 191, 191);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
    }
  }
`;

export interface InputNumberProps<T = string> {
  onChange: (value: T | null) => void;
}

export const InputNumberStyled = <T,>() => styled(InputNumber)<InputNumberProps<T>>`
  ${InputNumberStyle}
`;
