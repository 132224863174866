import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import {
  CheckboxStyled,
  InputPasswordStyled,
  InputStyled,
  MaskedInputStyled,
  SubmitButtonStyled,
} from "./loginForm";
import { PHONE } from "../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { LabelStyled } from "../../car/components/addCar/form";
import { RegisterThunk } from "../redux/auth.thunks";

const CheckboxTermsStyled = styled(CheckboxStyled)`
  color: rgb(191, 191, 191);
`;
const NavLinkStyled = styled(NavLink)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
  color: rgb(116, 99, 232);
`;

export const RegisterForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isPending = useAppSelector((state) => state.auth.isPending);
  const registerError = useAppSelector((state) => state.auth.registerError);
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (registerError) {
      for (const er in registerError) {
        form.setFields([
          {
            name: er,
            errors: [t(registerError[er])],
          },
        ]);
      }
    }
  }, [registerError, form, t]);
  const onFinish = (values: { phone: string; password: string; name: string }) => {
    dispatch(
      RegisterThunk({
        userData: {
          password: values.password,
          phone: values.phone.replace(/\s/g, "").replace("+", ""),
          name: values.name,
        },
      }),
    );
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 17) {
      form.setFieldsValue({ phone: phone });
      return;
    }

    setPhone(e.target.value);
    form.setFieldsValue({ phone: e.target.value });
  };

  return (
    <Form name="register" layout="vertical" onFinish={onFinish} disabled={isPending} form={form}>
      <Form.Item
        label={<LabelStyled>{t("auth.name.label")}</LabelStyled>}
        name="name"
        rules={[{ required: true, message: t("auth.name.error") }]}
      >
        <InputStyled />
      </Form.Item>
      <Form.Item
        name={PHONE}
        label={<LabelStyled>{t("auth.phone.label")}</LabelStyled>}
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t("auth.phone.error") },
          { min: 17, max: 17, message: t("auth.phone.error-format", { length: 17 }) },
        ]}
      >
        <MaskedInputStyled
          mask="+380 99 999 99 99"
          value={phone}
          alwaysShowMask={false}
          onChange={handleChangePhone}
          placeholder="+380  "
          maskChar=""
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (props) => <Input {...props} />
          }
        </MaskedInputStyled>
      </Form.Item>

      <Form.Item
        label={<LabelStyled>{t("auth.password.label")}</LabelStyled>}
        name="password"
        rules={[
          { required: true, message: t("auth.password.error") },
          { min: 6, message: t("auth.password.error-length", { length: 6 }) },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
            message: t("auth.password.error-format"),
          },
        ]}
      >
        <InputPasswordStyled />
      </Form.Item>
      <Form.Item
        label={<LabelStyled>{t("auth.password.repeat")}</LabelStyled>}
        name="password-repeat"
        dependencies={["password"]}
        rules={[
          { required: true, message: t("auth.password.error") },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("auth.password.not-match")));
            },
          }),
        ]}
      >
        <InputPasswordStyled />
      </Form.Item>
      <Form.Item>
        <Form.Item
          name="confirm"
          valuePropName="checked"
          noStyle
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t("auth.terms.error"))),
            },
          ]}
        >
          <CheckboxTermsStyled>
            {t("auth.agree.label")}
            <NavLinkStyled to="/terms" target="_blank">
              {t("auth.terms.label")}
            </NavLinkStyled>
          </CheckboxTermsStyled>
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <SubmitButtonStyled block type="primary" htmlType="submit">
          {t("auth.register.label")}
        </SubmitButtonStyled>
      </Form.Item>
    </Form>
  );
};
