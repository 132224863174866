import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PowerInline = () => {
  const dispatch = useAppDispatch();
  const { manageSearch } = useSearch();
  const selectedPower = useAppSelector((state) => state.filters.selectedData.power);
  const [power, setPower] = useState(selectedPower);
  const handleChangePower = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setPower((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    setPower(selectedPower);
    if (selectedPower?.from) {
      manageSearch("powerFrom", String(selectedPower.from));
    }
    if (selectedPower?.to) {
      manageSearch("powerTo", String(selectedPower.to));
    }
  }, [selectedPower, manageSearch]);

  const handleBlur = (type: FromToEnum) => () => {
    if (power) {
      dispatch(FiltersActions.setPowers({ type, value: power[type] }));
    }
  };
  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={power?.from}
        onChange={handleChangePower(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        min={0}
      />
      <InputNumberStyled
        value={power?.to}
        onChange={handleChangePower(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        min={0}
      />
    </FromToWrapperStyled>
  );
};
