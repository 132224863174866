import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { SelectStyledMulty } from "../../searchFilter";

export const GearSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { manageAdvanceSearch } = useSearch();
  const gears = useAppSelector((state) => state.car.gear);
  const selectedGear = useAppSelector((state) => state.filters.selectedData?.gear);

  const hangleGear = (value: string[]) => {
    dispatch(FiltersActions.setGears(value));
    manageAdvanceSearch("gear", value);
  };
  return (
    <SelectStyledMulty
      options={gears}
      value={selectedGear}
      // onSelect={handleModel}
      onChange={hangleGear}
      // onClear={handleClear}
      placeholder={t("addCar.gear.label")}
      mode="multiple"
      showSearch
      allowClear
      size="large"
    />
  );
};
