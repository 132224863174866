import { FormInstance } from "antd";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { MODIFICATION } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { SelectStyled } from "../../shared/Input";

export const ModificationAddCar: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car.modification);
  const modification = useAppSelector((state) => state.car.selectedData.modification);

  const handleString = useCallback(
    (value: string, option: { searchName: string; name: string }) => {
      console.log(value, option);
      dispatch(CarActions.setModification({ value: option.searchName, name: option.name }));
    },
    [dispatch],
  );

  useEffect(() => {
    form.setFieldsValue({ modification });
  }, [modification, form]);

  const handleClear = () => {
    dispatch(CarActions.clearModel());
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.modification.label")}</LabelStyled>}
      name={MODIFICATION}
      rules={[{ required: true, message: t("addCar.modification.error") }]}
    >
      <SelectStyled
        placeholder={t("addCar.modification.placeholder")}
        options={options}
        showSearch
        allowClear
        onSelect={handleString}
        onClear={handleClear}
      />
    </FormItemStyled>
  );
};
