import { Button, Col, Dropdown, Flex, MenuProps, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { usePageTracking } from "../../helpers/pageTracking";
import { useAppDispatch, useAppSelector } from "../../helpers/store.hook";
import { CarRoutesPath, UserRoutesPath } from "../../routes";
import { LoginComponent } from "../auth/login";
import { AuthActions } from "../auth/redux/auth.slice";
import { AuthOpenState } from "../auth/types";
import { deleteTokenLocal } from "../auth/utils/tokenLocal";
import { FiltersActions } from "../filters/redux/filters.slice";

const LogoStyled = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  border-radius: 5px;
  background-color: blue;
  color: white;

  &:hover {
    background-color: darkblue;
    color: white;
  }
`;

const LoginButtonStyled = styled(Button)`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const AddCarButtonStyled = styled(LoginButtonStyled)`
  background-color: var(--main-color);
  color: rgb(250, 250, 250);
`;

const ActionWrapperStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 10px;
`;

const UserActionStyled = styled.div`
  border-radius: 8px;
  background-color: var(--main-color);
  padding: 5px 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(250, 250, 250);
  font-family: Roboto;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 1px;
`;

const NavLinkStyled = styled(NavLink)`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--main-color) !important;
    & svg * {
      fill: var(--main-color);
    }
  }

  & svg {
    margin-right: 10px;
    & * {
      fill: rgb(46, 46, 46);
    }
  }
`;

const LogOutStyled = styled(Button)`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 10px;
    & * {
      fill: rgb(46, 46, 46);
    }
  }
`;

const DropdownItemStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;

  background: rgb(255, 255, 255);
  & ul {
    box-shadow: none !important;
  }
  & .ant-dropdown-menu-item {
    padding: 16px !important;
  }

  & .ant-dropdown-menu-title-content {
  }
`;

export const Header = () => {
  const dispatch = useAppDispatch();
  usePageTracking();

  const handleClearSearch = useCallback(() => {
    dispatch(FiltersActions.clearSelectedData());
    dispatch(FiltersActions.clearBrand());
  }, [dispatch]);
  return (
    <Row>
      <Col span={24}>
        <Flex
          justify={"space-between"}
          style={{
            border: "1px solid darkgrey",
            padding: "5px",
            borderRadius: "5px 5px 0 0 ",
          }}
        >
          <div>
            <LogoStyled onClick={handleClearSearch} to="/">
              Muna
            </LogoStyled>
          </div>
          <ActionWrapperStyled>
            <AddCarButton />
            <LoginButton />
          </ActionWrapperStyled>
        </Flex>
      </Col>
    </Row>
  );
};

const LoginButton = React.memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const user = useAppSelector((state) => state.auth);

  const handleLogOut = useCallback(() => {
    deleteTokenLocal();
    dispatch(AuthActions.logOut());
  }, [dispatch]);

  const handleLogin = () => {
    dispatch(AuthActions.openAuth(AuthOpenState.LOGIN));
  };

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: (
          <NavLinkStyled to={UserRoutesPath.user + UserRoutesPath.cars}>
            <svg
              width="20.000000"
              height="16.000000"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                id="Vector"
                d="M18.27 6.18L17.8 5L16.97 2.93C16.26 1.15 14.56 0 12.64 0L7.35 0C5.43 0 3.73 1.15 3.02 2.93L2.19 5L1.72 6.18C0.71 6.56 0 7.52 0 8.66L0 10.66C0 11.33 0.25 11.94 0.66 12.41L0.66 14.66C0.66 15.4 1.26 16 2 16L3.33 16C4.06 16 4.66 15.4 4.66 14.66L4.66 13.33L15.33 13.33L15.33 14.66C15.33 15.4 15.93 16 16.66 16L18 16C18.73 16 19.33 15.4 19.33 14.66L19.33 12.41C19.74 11.94 20 11.33 20 10.66L20 8.66C20 7.52 19.28 6.56 18.27 6.18ZM5.49 3.92C5.8 3.16 6.53 2.66 7.35 2.66L12.64 2.66C13.46 2.66 14.19 3.16 14.5 3.92L15.33 6L4.66 6L5.49 3.92ZM3.33 10.65C2.53 10.65 2 10.12 2 9.32C2 8.53 2.53 8 3.33 8C4.13 8 5.33 9.19 5.33 9.99C5.33 10.79 4.13 10.65 3.33 10.65ZM16.66 10.65C15.86 10.65 14.66 10.79 14.66 9.99C14.66 9.19 15.86 8 16.66 8C17.46 8 18 8.53 18 9.32C18 10.12 17.46 10.65 16.66 10.65Z"
                fill="#2E2E2E"
                fillOpacity="1.000000"
                fillRule="evenodd"
              />
            </svg>
            {t("header.dropdown.my-cars")}
          </NavLinkStyled>
        ),
      },
      {
        key: "2",
        label: (
          <NavLinkStyled to={CarRoutesPath.car + CarRoutesPath.favourite}>
            <svg
              width="20.000000"
              height="17.215088"
              viewBox="0 0 20 17.2151"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                id="Vector"
                d="M0 5.85C0 10.71 4.01 13.3 6.96 15.62C8 16.44 9 17.21 10 17.21C11 17.21 12 16.44 13.03 15.62C15.98 13.3 20 10.71 20 5.85C20 0.98 14.49 -2.46 10 2.21C5.5 -2.46 0 0.98 0 5.85Z"
                fill="#7463E8"
                fillOpacity="1.000000"
                fillRule="nonzero"
              />
            </svg>

            {t("header.dropdown.favourite")}
          </NavLinkStyled>
        ),
      },
      {
        key: "3",
        label: (
          <NavLinkStyled to={UserRoutesPath.user + UserRoutesPath.settings}>
            <svg
              width="19.000000"
              height="20.000000"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                id="Vector"
                d="M11.27 0.15C10.9 0 10.43 0 9.5 0C8.56 0 8.09 0 7.72 0.15C7.22 0.35 6.83 0.74 6.63 1.23C6.53 1.45 6.5 1.71 6.48 2.09C6.46 2.65 6.17 3.17 5.69 3.45C5.2 3.72 4.6 3.71 4.11 3.45C3.77 3.28 3.52 3.18 3.28 3.15C2.75 3.08 2.22 3.22 1.79 3.54C1.47 3.78 1.24 4.19 0.77 4.99C0.3 5.8 0.06 6.21 0.01 6.6C-0.06 7.13 0.09 7.66 0.41 8.08C0.56 8.27 0.77 8.43 1.09 8.63C1.57 8.93 1.88 9.44 1.88 10C1.88 10.55 1.57 11.06 1.09 11.36C0.77 11.56 0.56 11.72 0.41 11.91C0.09 12.33 -0.06 12.86 0.01 13.39C0.06 13.78 0.3 14.19 0.77 15C1.24 15.8 1.47 16.21 1.79 16.45C2.21 16.77 2.75 16.91 3.28 16.84C3.52 16.81 3.77 16.71 4.11 16.54C4.6 16.28 5.2 16.27 5.69 16.54C6.17 16.82 6.46 17.34 6.48 17.9C6.5 18.28 6.53 18.54 6.63 18.76C6.83 19.25 7.22 19.64 7.72 19.84C8.09 20 8.56 20 9.5 20C10.43 20 10.9 20 11.27 19.84C11.77 19.64 12.16 19.25 12.36 18.76C12.46 18.54 12.49 18.28 12.51 17.9C12.53 17.34 12.82 16.82 13.3 16.54C13.79 16.27 14.39 16.28 14.88 16.54C15.22 16.71 15.47 16.81 15.71 16.84C16.24 16.91 16.77 16.77 17.2 16.45C17.52 16.21 17.75 15.8 18.22 14.99C18.69 14.19 18.93 13.78 18.98 13.39C19.05 12.86 18.9 12.33 18.58 11.91C18.43 11.72 18.22 11.56 17.9 11.36C17.42 11.06 17.11 10.55 17.11 9.99C17.11 9.44 17.42 8.93 17.9 8.63C18.22 8.43 18.43 8.27 18.58 8.08C18.9 7.66 19.05 7.13 18.98 6.6C18.93 6.21 18.69 5.8 18.22 5C17.75 4.19 17.52 3.78 17.2 3.54C16.78 3.22 16.24 3.08 15.71 3.15C15.47 3.18 15.22 3.28 14.88 3.45C14.39 3.71 13.79 3.73 13.3 3.45C12.82 3.17 12.53 2.65 12.51 2.09C12.49 1.71 12.46 1.45 12.36 1.23C12.16 0.74 11.77 0.35 11.27 0.15ZM9.5 13C11.16 13 12.52 11.65 12.52 10C12.52 8.34 11.16 7 9.5 7C7.83 7 6.47 8.34 6.47 10C6.47 11.65 7.83 13 9.5 13Z"
                fill="#2E2E2E"
                fillOpacity="1.000000"
                fillRule="evenodd"
              />
            </svg>
            {t("header.dropdown.settings")}
          </NavLinkStyled>
        ),
      },
      {
        key: "4",
        label: (
          <LogOutStyled onClick={handleLogOut}>
            <svg
              width="17.000000"
              height="18.000000"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                id="Vector"
                d="M0 6.56L0 11.43C0 13.72 0 14.87 0.72 15.58C1.37 16.23 2.39 16.29 4.31 16.29C4.21 15.62 4.19 14.81 4.19 13.86C4.18 13.46 4.51 13.13 4.92 13.13C5.33 13.13 5.66 13.45 5.66 13.86C5.67 14.92 5.7 15.67 5.8 16.25C5.91 16.8 6.07 17.12 6.31 17.35C6.58 17.62 6.97 17.8 7.69 17.89C8.43 17.99 9.42 18 10.84 18L11.82 18C13.24 18 14.22 17.99 14.97 17.89C15.69 17.8 16.07 17.62 16.35 17.35C16.62 17.08 16.8 16.71 16.89 15.99C16.99 15.26 17 14.28 17 12.89L17 5.1C17 3.71 16.99 2.73 16.89 2C16.8 1.28 16.62 0.91 16.35 0.64C16.07 0.37 15.69 0.19 14.97 0.1C14.22 0 13.24 0 11.82 0L10.84 0C9.42 0 8.43 0 7.69 0.1C6.97 0.19 6.58 0.37 6.31 0.64C6.07 0.87 5.91 1.19 5.8 1.74C5.7 2.32 5.67 3.07 5.66 4.13C5.66 4.54 5.33 4.86 4.92 4.86C4.51 4.86 4.18 4.53 4.19 4.13C4.19 3.18 4.21 2.37 4.31 1.7C2.39 1.7 1.37 1.76 0.72 2.41C0 3.12 0 4.27 0 6.56ZM2.43 9.51C2.14 9.23 2.14 8.76 2.43 8.48L4.4 6.53C4.69 6.25 5.16 6.25 5.45 6.53C5.73 6.82 5.73 7.28 5.45 7.57L4.74 8.27L11.82 8.27C12.23 8.27 12.56 8.59 12.56 9C12.56 9.4 12.23 9.72 11.82 9.72L4.74 9.72L5.45 10.42C5.73 10.71 5.73 11.17 5.45 11.46C5.16 11.74 4.69 11.74 4.4 11.46L2.43 9.51Z"
                fill="#2E2E2E"
                fillOpacity="1.000000"
                fillRule="evenodd"
              />
            </svg>
            {t("header.dropdown.log-uot")}
          </LogOutStyled>
        ),
      },
    ],
    [handleLogOut, t],
  );

  return (
    <>
      {isLogin ? (
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          trigger={["click"]}
          dropdownRender={(menus) => (
            <DropdownItemStyled className="123">{menus}</DropdownItemStyled>
          )}
        >
          <UserActionStyled>{user?.name || user?.phone}</UserActionStyled>
        </Dropdown>
      ) : (
        <>
          <LoginButtonStyled onClick={handleLogin}>{t("auth.login-register")}</LoginButtonStyled>
          <LoginComponent />
        </>
      )}
    </>
  );
});

//TODO add navbar to go throu user page
// TODO ADD user page with list of user cars and adverts

const AddCarButton = React.memo(() => {
  const { t } = useTranslation();
  return (
    <NavLink to={CarRoutesPath.car + CarRoutesPath.add}>
      <AddCarButtonStyled>{t("header.add-car")}</AddCarButtonStyled>
    </NavLink>
  );
});
