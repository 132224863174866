import { Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { LoginForm } from "./loginForm";
import { RegisterForm } from "./registerForm";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { AuthActions } from "../redux/auth.slice";
import { AuthOpenState } from "../types";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoStyled = styled.div`
  margin-bottom: 30px;
`;

const HeaderActionsStyled = styled.div`
  margin-bottom: 30px;
`;

const HeaderActionItemStyled = styled(Radio.Button)`
  background: rgb(240, 240, 240);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  height: 47px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: var(--main-color);
    border-color: var(--main-color);
  }
  &:hover {
    color: #fff;
    background: var(--main-color) !important;
    border-color: var(--main-color) !important;
  }
`;

export const LoginComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAuthOpen = useAppSelector((state) => state.auth.isAuthOpen);

  const handleSwitch = (value: AuthOpenState) => () => {
    dispatch(AuthActions.toggleAuth(value));
  };

  const handleClose = () => {
    dispatch(AuthActions.closeAuth());
  };

  return (
    <Modal open={!!isAuthOpen} width={550} footer={null} onCancel={handleClose} destroyOnClose>
      <HeaderWrapper>
        <LogoStyled>Muna</LogoStyled>

        <HeaderActionsStyled>
          <Radio.Group buttonStyle="solid" value={isAuthOpen}>
            <HeaderActionItemStyled
              value={AuthOpenState.LOGIN}
              onClick={handleSwitch(AuthOpenState.LOGIN)}
            >
              {t("auth.login.action")}
            </HeaderActionItemStyled>
            <HeaderActionItemStyled
              value={AuthOpenState.REGISTER}
              onClick={handleSwitch(AuthOpenState.REGISTER)}
            >
              {t("auth.register.action")}
            </HeaderActionItemStyled>
          </Radio.Group>
        </HeaderActionsStyled>
      </HeaderWrapper>
      {isAuthOpen === AuthOpenState.LOGIN ? <LoginForm /> : <RegisterForm />}
    </Modal>
  );
};
