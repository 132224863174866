import { Route, Routes } from "react-router-dom";

import { ProtectedAuthRoutes } from "./protectedAuthRoutes";
import { AuthRoutesPath } from "..";
import { ResetPassword } from "../../pages/auth/resetPassword";

export const Auth = () => {
  return (
    <Routes>
      <Route element={<ProtectedAuthRoutes />}>
        <Route path="/" element={<div>auth /</div>} />
        <Route path={AuthRoutesPath.resetPassword} element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};
