import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemProps, FormItemStyled, LabelStyled } from "./form";
import { VIN } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { InputStyled } from "../../shared/Input";

export const VinAddCar: React.FC<FormItemProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const vin = useAppSelector((state) => state.car.selectedData?.vin);
  useEffect(() => {
    form.setFieldsValue({ vin });
  }, [vin, form]);

  const handleChange = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    if (e) {
      dispatch(CarActions.setValue({ key: VIN, value: e.target.value }));
    }
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.vin.label")}</LabelStyled>}
      name={VIN}
      rules={[
        { required: true, message: t("addCar.vin.error") },
        { min: 17, max: 17, message: t("addCar.vin.error-length") },
      ]}
    >
      <InputStyled onChange={handleChange} placeholder={t("addCar.vin.placeholder")} />
    </FormItemStyled>
  );
};
