import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PriceInline = () => {
  const dispatch = useAppDispatch();
  const { manageSearch } = useSearch();
  const selectedPrice = useAppSelector((state) => state.filters.selectedData.price);
  const [price, setPrice] = useState(selectedPrice);
  const handleChangePrice = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setPrice((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    setPrice(selectedPrice);
    if (selectedPrice?.from) {
      manageSearch("priceFrom", String(selectedPrice.from));
    }
    if (selectedPrice?.to) {
      manageSearch("priceTo", String(selectedPrice.to));
    }
  }, [selectedPrice, manageSearch]);

  const handleBlur = (type: FromToEnum) => () => {
    if (price) {
      dispatch(FiltersActions.setPrice({ type, value: price[type] }));
    }
  };

  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={price?.from}
        onChange={handleChangePrice(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        step={100}
        min={0}
      />
      <InputNumberStyled
        value={price?.to}
        onChange={handleChangePrice(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        step={100}
        min={0}
      />
    </FromToWrapperStyled>
  );
};
