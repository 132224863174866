import { Input, InputNumber, Select } from "antd";
import styled, { css } from "styled-components";

const style = css`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  height: 56px;

  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  &:hover {
    border-color: var(--main-color);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    border-color: var(--main-color);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
`;

export const InputStyled = styled(Input)`
  ${style}
  padding: 16px;
`;

export const InputNumberStyled = styled(InputNumber)<{ onChange: (value: number | null) => void }>`
  ${style}
  width: 100%;
  display: flex;
  align-items: center;
  & .ant-input-number-input-wrap {
    width: 100%;
  }
  & input {
    width: 100%;
    color: rgb(46, 46, 46);
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
`;

export const SelectStyled = styled(Select)<{
  onSelect?: (
    value: string,
    options: {
      _id: string;
      value: string;
      name: string;
      searchName: string;
      parentId?: string;
      isParent?: string;
      image: string;
    },
  ) => void;
}>`
  ${style}
`;
