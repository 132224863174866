import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { manageFavorite } from "../../../components/auth/redux/auth.thunks";
import { CarItem } from "../../../components/car/CarTypes";
import axios from "../../../helpers/interseptor";
import { useAppDispatch } from "../../../helpers/store.hook";
import { CarRoutesPath } from "../../../routes";

export const CarFavorite: React.FC = () => {
  const [cars, setCars] = useState<CarItem[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    axios
      .get("/user/favorites")
      .then((res) => res.data)
      .then((data) => setCars(data));
  }, []);

  const handleFaivorite = (id: string) => {
    dispatch(manageFavorite({ id, isNew: false }));
  };

  return (
    <>
      <div>
        {cars.map((car) => (
          <div key={car._id}>
            <img src={car?.photo[0] || ""} alt={car.brand} />
            <Link to={`${CarRoutesPath.car}/${car._id}`}>go to car</Link>
            <button
              onClick={() => {
                handleFaivorite(car._id);
              }}
            >
              delete
            </button>
          </div>
        ))}
      </div>
    </>
  );
};
