import { Button, Dropdown, Timeline } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { SearchRoutesPath } from "../../../../routes";
import { BTNTypes, ButtonStyled } from "../../../common/Button";
import { FromToEnum } from "../../../filters/filters.types";
import { SearchHistory } from "../../../search/search.types";

const HistoryItemStyled = styled.div<{ $isOpen?: boolean }>`
  border-radius: 8px;
  background: rgb(255, 255, 255);
  padding: 24px;
  width: 386px;
  height: ${(props) => (props.$isOpen ? "auto" : "400px")};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & .ant-timeline-item-tail {
    background: var(--main-color) !important;
    border-inline-start: 2px dashed rgba(5, 5, 5, 0.06);
  }

  & .ant-timeline-item-last {
    padding-bottom: 0;
  }

  & .ant-timeline {
    padding-top: 5px;
  }
`;

const ItemHeaderStyled = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

const HeaderActionStyled = styled(Button)`
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
`;

const ItemTimeStyled = styled.div`
  color: rgb(191, 191, 191);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
`;

const ItemActionWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  & > button {
    padding: 16px;
  }
`;

const ItemDotStyled = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--main-color);
`;

const ItemStepTitleStyled = styled.div`
  color: rgb(191, 191, 191);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: left;
`;

const ItemStepItemStyled = styled.div`
  color: rgb(72, 72, 72);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
  letter-spacing: 0%;
  text-align: left;
`;

const TimeLineWrapperStyled = styled.div`
  position: relative;
  overflow: hidden;
`;

const ShowMoreStyledWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  z-index: 12;
  width: 100%;
  bottom: 0;
  justify-content: center;
  background: ${(props) =>
    props.$isOpen ? "transparent" : "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))"};
`;

const ShowMoreStyled = styled(Button)`
  width: 100%;
  border-radius: 0;
  padding: 0;
  border: none;
  opacity: 0;
  &:hover {
    opacity: 1;
    background: white !important;
  }
`;

export interface HistoryItemValue {
  link: string;
  date: Date;
  id: string;
  value: {
    brand?: string;
    model?: string;
    generation?: { [key: string]: string[] };
    priceFrom?: string;
    priceTo?: string;
    yearFrom?: string;
    yearTo?: string;
  };
}

export const HistoryItem: React.FC<{ item: HistoryItemValue; onDelete: (id: string) => void }> = ({
  item,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    onDelete(item.id);
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const items = [
    {
      label: <div onClick={handleDelete}>{t("delete")}</div>,
      key: "0",
    },
  ];

  const getGenerations = (data: HistoryItemValue["value"]["generation"]) => {
    if (!data) return [];
    return Object.keys(data).map((key) => {
      return {
        children: (
          <>
            <ItemStepTitleStyled>{key}</ItemStepTitleStyled>
            <ItemStepItemStyled>{data[key].join(", ")}</ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      };
    });
  };

  const TimelineItems = useMemo(() => {
    const data = [];

    if (item.value.brand) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("brand")}</ItemStepTitleStyled>
            <ItemStepItemStyled>{item.value.brand}</ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.model) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("model")}</ItemStepTitleStyled>
            <ItemStepItemStyled>{item.value.model}</ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.generation) {
      data.push(...getGenerations(item.value.generation));
    }
    if (item.value.priceFrom && item.value.priceTo) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("price")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              ${item.value.priceFrom} - ${item.value.priceTo}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }

    if (item.value.priceFrom && !item.value.priceTo) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("price")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              {t(FromToEnum.FROM)} ${item.value.priceFrom}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.priceTo && !item.value.priceFrom) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("price")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              {t(FromToEnum.TO)} ${item.value.priceTo}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.yearFrom && item.value.yearTo) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("year")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              {item.value.yearFrom} - {item.value.yearTo}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.yearFrom && !item.value.yearTo) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("year")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              {t(FromToEnum.FROM)} {item.value.yearFrom}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (item.value.yearTo && !item.value.yearFrom) {
      data.push({
        children: (
          <>
            <ItemStepTitleStyled>{t("year")}</ItemStepTitleStyled>
            <ItemStepItemStyled>
              {t(FromToEnum.TO)} {item.value.yearTo}
            </ItemStepItemStyled>
          </>
        ),
        color: "var(--main-color)",
        dot: <ItemDotStyled />,
      });
    }
    if (data.length > 3) {
      setIsShowMore(true);
    }
    return data;
  }, [
    item.value.brand,
    item.value.model,
    item.value.generation,
    t,
    item.value.priceFrom,
    item.value.priceTo,
    item.value.yearFrom,
    item.value.yearTo,
  ]);

  const handleSearch = () => {
    const history = localStorage.getItem(SearchHistory.history);
    if (history) {
      const historyParsed = JSON.parse(history) as HistoryItemValue[];
      const itemIndex = historyParsed.findIndex((history) => history.id === item.id);
      if (itemIndex !== -1) {
        const selectedItem = historyParsed.splice(itemIndex, 1)[0];
        historyParsed.unshift(selectedItem);
        localStorage.setItem(SearchHistory.history, JSON.stringify(historyParsed));
      }
    }
  };

  return (
    <>
      <HistoryItemStyled $isOpen={isOpen}>
        <ItemHeaderStyled>
          <ItemTimeStyled>{new Date(item.date).toLocaleString()}</ItemTimeStyled>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <HeaderActionStyled type="text">
              <svg
                width="10.781982"
                height="2.166668"
                viewBox="0 0 10.782 2.16667"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="more_horiz"
                  d="M1.07 2.16C0.77 2.16 0.52 2.06 0.31 1.84C0.1 1.63 0 1.37 0 1.07C0 0.77 0.1 0.52 0.31 0.31C0.53 0.1 0.78 0 1.08 0C1.38 0 1.64 0.1 1.85 0.31C2.06 0.53 2.16 0.78 2.16 1.08C2.16 1.38 2.06 1.64 1.84 1.85C1.63 2.06 1.37 2.16 1.07 2.16ZM5.38 2.16C5.08 2.16 4.83 2.06 4.62 1.84C4.41 1.63 4.3 1.37 4.3 1.07C4.3 0.77 4.41 0.52 4.62 0.31C4.84 0.1 5.09 0 5.39 0C5.69 0 5.94 0.1 6.15 0.31C6.36 0.53 6.47 0.78 6.47 1.08C6.47 1.38 6.36 1.64 6.15 1.85C5.94 2.06 5.68 2.16 5.38 2.16ZM9.69 2.16C9.39 2.16 9.14 2.06 8.93 1.84C8.72 1.63 8.61 1.37 8.61 1.07C8.61 0.77 8.72 0.52 8.93 0.31C9.14 0.1 9.4 0 9.7 0C10 0 10.25 0.1 10.46 0.31C10.67 0.53 10.78 0.78 10.78 1.08C10.78 1.38 10.67 1.64 10.46 1.85C10.24 2.06 9.99 2.16 9.69 2.16Z"
                  fill="#1C1B1F"
                  fillOpacity="1.000000"
                  fillRule="nonzero"
                />
              </svg>
            </HeaderActionStyled>
          </Dropdown>
        </ItemHeaderStyled>
        <TimeLineWrapperStyled>
          <Timeline items={TimelineItems} />
          {isShowMore && (
            <ShowMoreStyledWrapper $isOpen={isOpen}>
              <ShowMoreStyled type="text" onClick={handleToggle}>
                {t(isOpen ? "history.hide" : "history.show-more")}
              </ShowMoreStyled>
            </ShowMoreStyledWrapper>
          )}
        </TimeLineWrapperStyled>
        <ItemActionWrapperStyled>
          <NavLink to={`${SearchRoutesPath.search}${item.link}`}>
            <ButtonStyled $type={BTNTypes.SECONDARY} onClick={handleSearch}>
              {t("search")}
            </ButtonStyled>
          </NavLink>
        </ItemActionWrapperStyled>
      </HistoryItemStyled>
    </>
  );
};
