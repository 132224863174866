import { Button } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PreviewCarItem } from "../../../../pages/car/preview/PreviewCar";

const ContactWrapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
`;

const PriceWrapperStyled = styled.div`
  background: rgb(240, 240, 240);
  padding: 16px;
`;

const LabelStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  margin-bottom: 8px;
`;

const PriceStyled = styled.div`
  color: rgb(116, 99, 232);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
`;

const UserInfoWrapperStyled = styled.div`
  padding: 16px;
`;

const UserInfoStyled = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

const UserAvatarStyled = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const UserNameStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0%;
`;

const ContantActionsWrapperStyled = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
`;
const ActionButtonStyled = styled(Button)<{ $primary?: boolean }>`
  display: flex;
  align-items: center;

  color: ${(props) => (props.$primary ? "rgb(116, 99, 232)" : "rgb(46, 46, 46)")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  height: auto;
  padding: 16px;

  & svg {
    margin-right: 8px;
  }

  & * {
    transition: fill 0.3s;
  }

  &:hover {
    background: var(--main-color) !important;
    color: white !important;

    & svg * {
      fill: white;
    }
  }
`;

const LocationTextStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;

  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;

const LocationWrapperStyled = styled.div`
  padding: 16px;
`;

interface ContactsProps {
  car: PreviewCarItem;
}

export const Contacts: React.FC<ContactsProps> = ({ car }) => {
  const { t } = useTranslation();
  return (
    <ContactWrapperStyled>
      <PriceWrapperStyled>
        <LabelStyled>{t("price")}</LabelStyled>
        <PriceStyled>$ {car.price.toLocaleString("en-US")}</PriceStyled>
      </PriceWrapperStyled>
      <UserInfoWrapperStyled>
        <LabelStyled>{t("seller")}</LabelStyled>
        <UserInfoStyled>
          <UserAvatarStyled>
            <img src={car.user.userPhoto} alt="avatar" />
          </UserAvatarStyled>
          <UserNameStyled>{car.user.name}</UserNameStyled>
        </UserInfoStyled>
      </UserInfoWrapperStyled>
      <ContantActionsWrapperStyled>
        <ActionButtonStyled $primary>
          <svg
            width="14.166687"
            height="14.166687"
            viewBox="0 0 14.1667 14.1667"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="call"
              d="M13.28 14.16C11.71 14.16 10.13 13.8 8.55 13.07C6.96 12.34 5.5 11.31 4.17 9.98C2.85 8.65 1.82 7.19 1.09 5.61C0.36 4.02 0 2.45 0 0.88C0 0.63 0.08 0.42 0.25 0.25C0.41 0.08 0.62 0 0.87 0L3.59 0C3.8 0 3.98 0.06 4.14 0.2C4.31 0.34 4.41 0.51 4.45 0.71L4.93 3.16C4.96 3.39 4.96 3.58 4.91 3.75C4.86 3.91 4.78 4.05 4.66 4.16L2.73 6.03C3.04 6.6 3.39 7.14 3.79 7.64C4.19 8.15 4.62 8.63 5.08 9.09C5.54 9.55 6.03 9.97 6.54 10.37C7.06 10.76 7.61 11.12 8.21 11.46L10.08 9.58C10.21 9.44 10.37 9.34 10.56 9.29C10.74 9.24 10.93 9.22 11.13 9.25L13.45 9.72C13.66 9.78 13.83 9.88 13.96 10.04C14.1 10.2 14.16 10.38 14.16 10.58L14.16 13.29C14.16 13.54 14.08 13.75 13.91 13.91C13.74 14.08 13.53 14.16 13.28 14.16Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
          {t("call")}
        </ActionButtonStyled>
        <ActionButtonStyled>
          <svg
            width="11.666687"
            height="13.541687"
            viewBox="0 0 11.6667 13.5417"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="flag"
              d="M0 13.54L0 0L6.74 0L7.08 1.66L11.66 1.66L11.66 9.16L6.58 9.16L6.25 7.5L1.25 7.5L1.25 13.54L0 13.54Z"
              fill="#2E2E2E"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
          {t("report")}
        </ActionButtonStyled>
      </ContantActionsWrapperStyled>
      <LocationWrapperStyled>
        <LabelStyled>{t("location")}</LabelStyled>
        <LocationTextStyled>
          <svg
            width="15.095947"
            height="19.009521"
            viewBox="0 0 15.0959 19.0095"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="location_on"
              d="M7.54 9.36C8.04 9.36 8.47 9.18 8.82 8.83C9.17 8.47 9.35 8.05 9.35 7.55C9.35 7.05 9.17 6.63 8.82 6.27C8.46 5.92 8.04 5.75 7.54 5.75C7.04 5.75 6.62 5.92 6.27 6.28C5.91 6.63 5.74 7.06 5.74 7.55C5.74 8.05 5.91 8.48 6.27 8.83C6.62 9.18 7.05 9.36 7.54 9.36ZM7.54 19C5.03 16.82 3.14 14.79 1.88 12.92C0.62 11.04 0 9.31 0 7.74C0 5.44 0.74 3.57 2.23 2.14C3.73 0.71 5.5 0 7.54 0C9.59 0 11.36 0.71 12.85 2.14C14.34 3.57 15.09 5.44 15.09 7.74C15.09 9.31 14.46 11.04 13.2 12.92C11.95 14.79 10.06 16.82 7.54 19Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
          {car.region} {t("region")}, {car.city ? `${t("city-short")} ${car.city}` : ""}
        </LocationTextStyled>
      </LocationWrapperStyled>
    </ContactWrapperStyled>
  );
};
