import { Select } from "antd";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

export const SelectStyle = css`
  height: auto !important;

  &:hover {
    &.ant-select-single.ant-select {
      & .ant-select-selector {
        border-color: var(--main-color) !important;
      }
    }
  }
  &:focus {
    border-color: var(--main-color);
  }

  & .ant-select-selector {
    &:focus-within {
      border-color: var(--main-color) !important;
      box-shadow: 0 0 0 2px rgb(80 5 255 / 10%) !important;
    }
  }

  &.ant-select-single.ant-select {
    & .ant-select-selector {
      border: 1px solid rgb(219, 219, 219);
      border-radius: 8px;
      padding: 11px 16px;
    }
  }

  & .ant-select-selection-placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
  }

  & .ant-select-selection-search-input {
    font-family: Roboto !important;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 24px;
    letter-spacing: 0;
    height: auto;
  }

  & .ant-select-selection-item {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
  }

  & .ant-select-selection-search {
    inset-inline-start: unset !important;
    inset-inline-end: unset !important;
  }
`;

export interface SelectProps<T = unknown> {
  onSelect?: (value: string, options: T) => void;
  onChange?: (value: string) => void;
  dropdownRender?: (originNode: ReactNode) => ReactNode;
}

export const SelectStyled = <T,>() => styled(Select)<SelectProps<T>>`
  ${SelectStyle}
`;
