import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { BTNTypes, ButtonStyled } from "../../../common/Button";
// import { GenerationList } from "../../../filters/components/cardLists/GenerationList";
// import { ModificationsList } from "../../../filters/components/cardLists/ModificationsList";
// import { SingleSelectionCard } from "../../../filters/components/cardLists/SingleSelectionList";
import {
  CardSearchEnum,
  CarModel,
  Generations,
  SelectedGeneration,
} from "../../../filters/filters.types";
import { Brands } from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../../filters/redux/filters.thunks";
import { MultiplePickCard } from "../../../search/shared/advancedSearch/MultiplePickCard";
import { SinglePickCard } from "../../../search/shared/advancedSearch/SinglePickCard";
import { SearchBtn } from "../../shared/SearchBtn";

const SelectWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const BrandPick = () => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector((state) => state.filters.brands);

  const handleClick = (value: Brands) => {
    dispatch(getModelsThunk(value));
  };

  return (
    <>
      {brands.slice(0, 9).map((brand) => (
        <SinglePickCard<Brands>
          size="small"
          key={brand._id}
          onClick={handleClick}
          value={brand}
          isSelected={false}
        />
      ))}
    </>
  );
};

const ModelPick = () => {
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);

  const options = useMemo(() => {
    if (selectedModel?.isSeries) {
      const model = models.find((model) => model._id === selectedModel?.parentId);
      if (model?.options?.length) {
        return model.options;
      }
      return [];
    }
    const model = models.find((model) => model._id === selectedModel?._id);

    if (model?.options?.length) {
      return model.options;
    }
    return models;
  }, [models, selectedModel]);

  const handleClick = (value: CarModel) => {
    if (selectedModel?._id === value._id) {
      return;
    }
    if (value?.options?.length) {
      dispatch(FiltersActions.setModel(value));
      return;
    }
    dispatch(getGenerationsThunk(value));
  };
  return (
    <>
      {options.map((model) => (
        <SinglePickCard<CarModel>
          key={model._id}
          onClick={handleClick}
          value={model}
          size="large"
          isSelected={false}
        />
      ))}
    </>
  );
};

const GenerationWrapperSelected = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > button {
    margin-top: 16px;
    text-align: center;
    padding: 16px;
  }
`;

const GenerationPick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);

  const handleClick = (generation: SelectedGeneration) => {
    dispatch(FiltersActions.setGenerationsMultyCard(generation));
  };

  const handleNext = () => {
    dispatch(FiltersActions.setSearchCartType(CardSearchEnum.MODIFICATION));
  };
  return (
    <GenerationWrapperSelected>
      <SelectWrapperStyled>
        {generations.map((generation) => (
          <SinglePickCard<Generations>
            key={generation._id}
            onClick={handleClick}
            value={generation}
            isSelected={selectedGenerations?.some((gen) => gen._id === generation._id)}
          />
        ))}
      </SelectWrapperStyled>
      <ButtonStyled
        $type={BTNTypes.SECONDARY}
        onClick={handleNext}
        disabled={selectedGenerations?.length === 0}
      >
        {t("next")}
      </ButtonStyled>
    </GenerationWrapperSelected>
  );
};

const ModificationPick = () => {
  const dispatch = useAppDispatch();

  const modificationsList = useAppSelector((state) => state.filters.selectedData.generations);
  // const selectedModifications = useAppSelector((state) => state.filters.selectedModifications);

  const handleClick = (value: SelectedGeneration) => {
    dispatch(FiltersActions.setMultyModification(value));
  };

  const handleModification = (parentId: string, modification: { _id: string }) => {
    dispatch(FiltersActions.setModification({ modification, parentId }));
  };

  return (
    <>
      {modificationsList?.map((generation) => (
        <MultiplePickCard<SelectedGeneration>
          key={generation._id}
          value={generation}
          onClick={handleClick}
          onOption={handleModification}
        />
      ))}
    </>
  );
};

const Items: Record<string, ReactElement> = {
  Brand: <BrandPick />,
  Model: <ModelPick />,
  Generation: <GenerationPick />,
  Modification: <ModificationPick />,
};

export const CardSearch = () => {
  const cardSearchType = useAppSelector((state) => state.filters.cardSearchType);

  return (
    <div>
      <SelectWrapperStyled>{Items[cardSearchType]}</SelectWrapperStyled>
      <ActionsStyled>
        <SearchBtn />
      </ActionsStyled>
    </div>
  );
};

const ActionsStyled = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > a > button {
    text-align: center;
    padding: 16px;
    width: 100%;
  }
`;
