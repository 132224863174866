import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemProps, FormItemStyled, LabelStyled } from "./form";
import { NUMBER } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { InputStyled } from "../../shared/Input";

export const NumberAddCar: React.FC<FormItemProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const number = useAppSelector((state) => state.car.selectedData?.number);

  useEffect(() => {
    form.setFieldsValue({ number });
  }, [number, form]);

  const handleChange = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    if (e) {
      dispatch(CarActions.setValue({ key: NUMBER, value: e.target.value }));
    }
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.number.label")}</LabelStyled>}
      name={NUMBER}
      rules={[
        { required: true, message: t("addCar.number.error") },
        { max: 10, message: t("addCar.number.error-max") },
      ]}
    >
      <InputStyled onChange={handleChange} placeholder={t("addCar.number.placeholder")} />
    </FormItemStyled>
  );
};
