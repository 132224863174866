import { useCallback, useEffect } from "react";
import { createSearchParams } from "react-router-dom";

import { debounce } from "../../../../helpers/debounce";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { SearchActions } from "../../redux/search.slice";
import { getCarsCountThunk } from "../../redux/search.thunks";
import { createSeach } from "../../utils/createSearch";

export const useSearchCount = () => {
  const dispatch = useAppDispatch();
  const selectedData = useAppSelector((state) => state.filters.selectedData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCarsCount = useCallback(
    debounce((search: string) => {
      dispatch(getCarsCountThunk(search));
    }, 1500),
    [dispatch],
  );

  useEffect(() => {
    const searchObject = createSeach(selectedData);
    const search = createSearchParams(searchObject);
    dispatch(SearchActions.setCarCountLoading(true));
    handleCarsCount(search);
  }, [handleCarsCount, selectedData, dispatch]);
};
