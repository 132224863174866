import { useCallback } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const manageSearch = useCallback(
    (key: string, value: string) => {
      setSearchParams((prev) => {
        prev.set(key, value);
        return prev;
      });
    },
    [setSearchParams],
  );

  const removeSearch = useCallback(
    (key: string) => {
      setSearchParams((prev) => {
        prev.delete(key);
        return prev;
      });
    },
    [setSearchParams],
  );

  const manageAdvanceSearch = useCallback(
    (key: string, value: string[]) => {
      const nextSearch: { [key: string]: string[] } = {};

      searchParams.forEach((value, key) => {
        if (nextSearch[key]) {
          nextSearch[key] = [...nextSearch[key], value];
        } else {
          nextSearch[key] = [value];
        }
      });

      nextSearch[key] = value;

      setSearchParams(createSearchParams(nextSearch).toString());
    },
    [searchParams, setSearchParams],
  );
  return { manageSearch, manageAdvanceSearch, removeSearch };
};
