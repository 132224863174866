import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const MileageInline = () => {
  const dispatch = useAppDispatch();
  const { manageSearch } = useSearch();
  const selectedMileage = useAppSelector((state) => state.filters.selectedData.mileage);
  const [mileage, setMileage] = useState(selectedMileage);
  const handleChangeMileage = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setMileage((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    setMileage(selectedMileage);
    if (selectedMileage?.from) {
      manageSearch("mileageFrom", String(selectedMileage.from));
    }
    if (selectedMileage?.to) {
      manageSearch("mileageTo", String(selectedMileage.to));
    }
  }, [selectedMileage, manageSearch]);

  const handleBlur = (type: FromToEnum) => () => {
    if (mileage) {
      dispatch(FiltersActions.setMileage({ type, value: mileage[type] }));
    }
  };

  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={mileage?.from}
        onChange={handleChangeMileage(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        min={0}
        step={1000}
      />
      <InputNumberStyled
        value={mileage?.to}
        onChange={handleChangeMileage(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        min={0}
        step={1000}
      />
    </FromToWrapperStyled>
  );
};
