import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UseInfo } from "../../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import i18next from "../../../../../i18n/config";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { SelectStyledMulty } from "../../searchFilter";

const CheckboxTextStyled = styled.div`
  font-family: "Roboto", sans-serif;
`;

const UseInfoOptions = [
  {
    value: UseInfo.NEW,
    label: i18next.t("isNewCar"),
  },
  {
    value: UseInfo.USED,
    label: i18next.t("used"),
  },
];
export const UseInfoSelect = () => {
  const { t } = useTranslation();
  const { manageAdvanceSearch, manageSearch } = useSearch();
  const dispatch = useAppDispatch();
  const selectedAfterAccident = useAppSelector((state) => state.filters.selectedData.afterAccident);
  const selectedUseInfo = useAppSelector((state) => state.filters.selectedData.useInfo);

  const handleUseInfo = (value: string[]) => {
    dispatch(FiltersActions.setUseInfos(value));
    manageAdvanceSearch("useInfo", value);
  };

  const onChangeAfterAccident = (e: CheckboxChangeEvent) => {
    dispatch(FiltersActions.setAfterAccident(e.target.checked));
    manageSearch("afterAccident", e.target.checked.toString());
  };

  return (
    <>
      <Checkbox onChange={onChangeAfterAccident} checked={selectedAfterAccident}>
        <CheckboxTextStyled>{t("afterAccident")}</CheckboxTextStyled>
      </Checkbox>
      <SelectStyledMulty
        options={UseInfoOptions}
        value={selectedUseInfo}
        onChange={handleUseInfo}
        placeholder={t("addCar.useInfo.label")}
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
    </>
  );
};
