import { Button } from "antd";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { Modification, SelectedGeneration } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { createGenSearch } from "../../../utils/createSearch";

const GenItemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const GenItemStyled = styled.div<{ $isSelected: boolean }>`
  border: 1px solid ${(props) => (props.$isSelected ? "var(--main-color)" : "rgb(219, 219, 219)")};
  background: ${(props) => (props.$isSelected ? "var(--main-color)" : "transparent")};
  color: ${(props) => (props.$isSelected ? "rgb(255,255,255)" : "rgb(108, 108, 108)")};
  border-radius: 8px;
  cursor: pointer;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
`;

const GenWrapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GenContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const GenLabelStyled = styled.div<{ $isSelected: boolean }>`
  border: 1px solid rgb(219, 219, 219);
  user-select: none;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
  cursor: pointer;
  background: ${(props) => (props.$isSelected ? "var(--main-color)" : "transparent")};
  color: ${(props) => (props.$isSelected ? "rgb(255,255,255)" : "rgb(108, 108, 108)")};
  & span {
    user-select: none;
    color: ${(props) => (props.$isSelected ? "rgb(255,255,255)" : "var(--main-color)")};
  }
`;

const GenItemButtonStyled = styled(Button)`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const Generations = React.memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData?.generations);
  const genList = useMemo(() => {
    return generations.map((item: SelectedGeneration) => {
      const isSelectedGen = selectedGenerations?.find((gen) => gen._id === item._id);
      if (isSelectedGen) return isSelectedGen;
      return item;
    }, []);
  }, [selectedGenerations, generations]);

  const handleModification = (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => {
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);

    if (isSelectedGen) {
      const nextGen = selectedGenerations?.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            options: gen.options.map((modItem) => {
              if (modItem._id === mod._id) {
                return {
                  ...modItem,
                  isSelected: !modItem.isSelected,
                };
              }
              return modItem;
            }),
          };
        }
        return item;
      });
      const res = createGenSearch(nextGen || []);
      dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));

      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    } else {
      const nextMod = gen.options.map((item) => {
        if (item._id === mod._id) {
          return {
            ...mod,
            isSelected: !mod.isSelected || true,
          };
        }
        return item;
      });
      const res = createGenSearch([...(selectedGenerations || []), { ...gen, options: nextMod }]);
      dispatch(
        FiltersActions.setGenerationSearch({
          nextGen: [...(selectedGenerations || []), { ...gen, options: nextMod }],
        }),
      );
      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    }
  };

  const handleAllModification = (gen: SelectedGeneration) => () => {
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);
    let nextGen: SelectedGeneration[] = [];
    if (isSelectedGen && selectedGenerations) {
      const isAllSelected = gen.options.every((item) => item.isSelected);

      nextGen = selectedGenerations.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            options: gen.options.map((modItem) => {
              return {
                ...modItem,
                isSelected: !isAllSelected,
              };
            }),
          };
        }
        return item;
      });
    } else {
      nextGen = [
        ...(selectedGenerations || []),
        { ...gen, options: gen.options.map((item) => ({ ...item, isSelected: true })) },
      ];
    }
    dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));
    const res = createGenSearch(nextGen || []);
    setSearchParams((prev) => {
      prev.set("generation", res);
      return prev;
    });
  };

  return (
    <GenWrapperStyled>
      {genList.map((gen) => {
        return (
          <GenContainerStyled key={gen._id}>
            <GenLabelStyled
              onClick={handleAllModification(gen)}
              $isSelected={gen.options.every((item) => item.isSelected)}
            >
              {gen.name}:{" "}
              <span>
                {gen.yearStart}-{gen.yearEnd || t("now")}
              </span>
            </GenLabelStyled>
            <GenItemWrapperStyled>
              <GenItem gen={gen} key={gen._id} onModification={handleModification} />
            </GenItemWrapperStyled>
          </GenContainerStyled>
        );
      })}
    </GenWrapperStyled>
  );
});

export const GenItem: React.FC<{
  gen: SelectedGeneration;
  onModification: (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => void;
}> = React.memo(({ gen, onModification }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectedMods = useMemo(() => {
    return gen.options.filter((mod) => mod.isSelected);
  }, [gen]);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleGeneration =
    (
      gen: SelectedGeneration,
      mod: Modification & {
        isSelected?: boolean | undefined;
      },
    ) =>
    () => {
      onModification(gen, mod);
    };
  return (
    <>
      {
        isOpen ? (
          <>
            {gen.options.map((mod) => (
              <GenItemStyled
                key={mod._id}
                $isSelected={!!mod.isSelected}
                onClick={handleGeneration(gen, mod)}
              >
                {mod.name}
              </GenItemStyled>
            ))}
          </>
        ) : (
          selectedMods.length
        )
        // selectedMods.map((mod) => (
        //   <GenItemStyled
        //     key={mod._id}
        //     $isSelected={!!mod.isSelected}
        //     onClick={handleGeneration(gen, mod)}
        //   >
        //     {mod.name}
        //   </GenItemStyled>
        // ))
      }

      <GenItemButtonStyled onClick={handleOpen}>
        {isOpen ? t("close") : t("open")}
      </GenItemButtonStyled>
    </>
  );
});
