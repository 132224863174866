import qs from "qs";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, createSearchParams } from "react-router-dom";
import { v4 } from "uuid";

import { useAppSelector } from "../../../helpers/store.hook";
import { SearchRoutesPath } from "../../../routes";
import { BTNTypes, ButtonStyled } from "../../common/Button";
import { SearchHistory } from "../../search/search.types";

export const SearchBtn = React.memo(() => {
  const { t } = useTranslation();
  const selectedData = useAppSelector((state) => state.filters.selectedData);

  const SearchUrl = useMemo(() => {
    const historyObject: {
      model?: string;
      brand?: string;
      generation?: { [key: string]: string[] };
      priceFrom?: string;
      priceTo?: string;
      yearFrom?: string;
      yearTo?: string;
    } = {};
    const searchObject: {
      model?: string;
      brand?: string;
      generation?: string;
      priceFrom?: string;
      priceTo?: string;
      yearFrom?: string;
      yearTo?: string;
      page: string;
      limit: string;
      afterAccident: string;
    } = {
      page: "1",
      limit: "20",
      afterAccident: "true",
    };
    if (selectedData?.model) {
      searchObject.model = selectedData.model.searchName;
      historyObject.model = selectedData.model.name;
    }
    if (selectedData?.brand) {
      searchObject.brand = selectedData.brand.searchName;
      historyObject.brand = selectedData.brand.name;
    }
    if (selectedData?.generations?.length) {
      const generations = selectedData.generations.reduce(
        (
          acc: { value: { [key: string]: string[] }; history: { [key: string]: string[] } },
          item,
        ) => {
          const selected = item.options.filter((mod) => mod.isSelected);
          const value = selected.map((mod) => mod.searchName);
          const history = selected.map((mod) => mod.name);

          acc.value[item.searchName] = value;
          acc.history[item.name] = history;
          return acc;
        },
        { value: {}, history: {} },
      );
      const stringified = qs.stringify(generations.value);
      historyObject.generation = generations.history;
      searchObject.generation = stringified;
    }
    if (selectedData?.price?.from) {
      searchObject.priceFrom = String(selectedData?.price.from);
      historyObject.priceFrom = String(selectedData?.price.from);
    }
    if (selectedData?.price?.to) {
      searchObject.priceTo = String(selectedData?.price.to);
      historyObject.priceTo = String(selectedData?.price.to);
    }
    if (selectedData?.year?.from) {
      searchObject.yearFrom = String(selectedData?.year.from);
      historyObject.yearFrom = String(selectedData?.year.from);
    }
    if (selectedData?.year?.to) {
      searchObject.yearTo = String(selectedData?.year.to);
      historyObject.yearTo = String(selectedData?.year.to);
    }
    return { link: `?${createSearchParams(searchObject)}`, history: historyObject };
  }, [selectedData]);

  const handleHistory = useCallback(() => {
    const history = localStorage.getItem(SearchHistory.history);
    if (SearchUrl.link === "?page=1&limit=20") return;
    if (history) {
      const parsedHistory = JSON.parse(history) as { date: Date; value: string }[];
      if (parsedHistory.length >= 5) {
        localStorage.setItem(
          SearchHistory.history,
          JSON.stringify([
            { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
            ...parsedHistory.slice(0, -1),
          ]),
        );
      } else {
        localStorage.setItem(
          SearchHistory.history,
          JSON.stringify([
            { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
            ...parsedHistory,
          ]),
        );
      }
    } else {
      localStorage.setItem(
        SearchHistory.history,
        JSON.stringify([
          { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
        ]),
      );
    }
  }, [SearchUrl]);

  return (
    <>
      <NavLink to={`${SearchRoutesPath.search}${SearchUrl.link}`}>
        <ButtonStyled $type={BTNTypes.PRIMARY} onClick={handleHistory}>
          {t("search")}
        </ButtonStyled>
      </NavLink>
    </>
  );
});
