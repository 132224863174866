import { Button, ConfigProvider, Modal, Slider } from "antd";
import { useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import placeholderImage from "./avatar-placeholder.png";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { updateUserPhoto } from "../../../auth/redux/auth.thunks";
import { getCroppedImg } from "../../../car/components/addCar/photo";
import { base64ToBlob } from "../../../car/utils/cropeImage";

const WrapperStyled = styled.div`
  max-width: 400px;
  width: 100%;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
`;

const HeaderStyled = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding: 24px;
  text-align: center;
`;

const PhotoWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  padding: 24px;
`;

const ImageWrapperStyled = styled.div`
  width: 190px;
  height: 190px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 24px;
`;
const ImagePreviewStyled = styled.img`
  margin-bpttom: 24px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const PhotoSubtitileStyled = styled.div`
  max-width: 310px;
  color: rgb(191, 191, 191);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 16px;
`;

const PhotoActionStyled = styled(Button)`
  border-radius: 8px;
  color: rgb(250, 250, 250);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  background: rgb(116, 99, 232);
  padding: 16px;
  height: 50px;
  position: relative;
`;

const UploadPhotoStyled = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  color: rgb(250, 250, 250);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  background: rgb(116, 99, 232);
  padding: 16px;
  height: 51px;
  position: relative;
  border: 1px solid rgb(116, 99, 232);
  &:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
`;

const InputFileStyled = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
`;

const acceptedImageTypes = [
  "image/jpeg",
  "image/jpg",
  "image/jpe",
  "image/jif",
  "image/png",
  "image/webp",
];

export const SettingsPhoto = () => {
  const { t } = useTranslation();

  const userPhoto = useAppSelector((state) => state.auth?.photo);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState<{ image: string | null; type: string | null }>({
    image: null,
    type: null,
  });

  const handleAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = e;
    if (!files) return;

    const FilterdImages = Array.from(files).filter((file) =>
      acceptedImageTypes.includes(file.type),
    );

    if (FilterdImages.length !== 0) {
      const imageDataUrl: string = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result as string), false);
        reader.readAsDataURL(FilterdImages[0]);
      });

      const cropedImage = await base64ToBlob(imageDataUrl, FilterdImages[0].type);
      const blobUrl = URL.createObjectURL(cropedImage);
      setImage({ image: blobUrl, type: FilterdImages[0].type });
      setIsOpen(true);
    }

    e.target.value = "";
  };

  const handleCancel = () => {
    setIsOpen(false);
    setImage({
      image: null,
      type: null,
    });
  };

  return (
    <WrapperStyled>
      <HeaderStyled>{t("user.settings.title-photo")}</HeaderStyled>
      <PhotoWrapperStyled>
        <ImageWrapperStyled>
          <ImagePreviewStyled src={userPhoto || placeholderImage} />
        </ImageWrapperStyled>

        <PhotoSubtitileStyled>
          Розмір зображення: мінімум 800x800 пікселів. Підтримувані формати: JPG або PNG.
        </PhotoSubtitileStyled>
        <UploadPhotoStyled>
          <InputFileStyled
            type="file"
            id="avatarInput"
            accept="image/jpeg,
            image/jpg,
            image/jpe,
            image/jif,
            image/png,
            image/webp"
            // disabled={photos.length >= maxFiles}
            onChange={handleAddImage}
            multiple={false}
          />
          Завантажити нове
        </UploadPhotoStyled>
        <PhotoActionStyled>Видалити</PhotoActionStyled>
      </PhotoWrapperStyled>
      <EditModal isOpen={isOpen} handleCancel={handleCancel} image={image} />
    </WrapperStyled>
  );
};

const CROP_AREA_ASPECT = 4 / 4;

const CropAreaStyled = styled.div`
  position: relative;
  height: 80%;
  width: 80%;
  background: rgb(255, 255, 255);
  margin: 0 auto;
`;

const CropWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
`;

const SaveActionsWrapperStyled = styled.div`
  margin-bottom: 5px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const UploadedImageActionButtonStyled = styled(Button)<{ $hbg?: string }>`
  border-radius: 4px;

  backdrop-filter: blur(100px);
  background: rgb(255, 255, 255);
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border: none !important;

  &:hover {
    background: ${(prop) => prop.$hbg || "rgb(231, 70, 70)"} !important;
    color: rgb(255, 255, 255) !important;
  }
`;

const CropActionsWrapperStyled = styled.div`
  width: 80%;
  margin: 50px auto;

  & .ant-slider .ant-slider-track {
    background-color: transparent;
  }

  & .ant-slider .ant-slider-rail {
    border-radius: 8px;

    background: rgb(109 102 102);
  }

  & .ant-slider-handle {
    &:after {
      border-radius: 8px;

      background: rgb(250, 250, 250) !important;
      width: 6px !important;
      height: 32px !important;
      box-shadow: none !important;
      transform: translate(0, -11px);
    }
  }
`;

const RotateActionWrapperStyled = styled.div`
  margin-top: 16px;
`;

const RotateButtonStyled = styled(Button)`
  background: transparent;
  border: none;
  &:hover {
    background: transparent !important;
    transform: scale(1.2);
  }
`;

const modalStyles = {
  mask: {
    backdropFilter: "blur(10px)",
  },
  body: {
    height: "80vh",
    background: "#2E2E2E",
  },
};
const EditModal: React.FC<{
  isOpen: boolean;
  handleCancel: () => void;
  image: { image: string | null; type: string | null };
}> = ({ isOpen, handleCancel, image }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const handleOn = async () => {
    const croppedImage = await getCroppedImg(
      image?.image || "",
      croppedAreaPixels,
      image?.type || "image/jpeg",
      rotate,
    );

    if (croppedImage) {
      dispatch(updateUserPhoto({ photo: croppedImage })).then((res) => {
        handleCancel();
      });
    }
  };
  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSliderChange = (value: number) => {
    setZoom(value);
  };

  const handleRotate = (angle: number) => () => {
    setRotate((prev) => prev + angle);
  };
  return (
    <ConfigProvider
      modal={{
        styles: modalStyles,
      }}
    >
      <Modal
        open={isOpen}
        onOk={handleOn}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        width={"90%"}
        className="AddCarModal"
        style={{ maxWidth: "1700px", zIndex: 10000 }}
      >
        <CropWrapperStyled>
          <SaveActionsWrapperStyled>
            <UploadedImageActionButtonStyled onClick={handleCancel}>
              {t("addCar.photo.cancel")}
            </UploadedImageActionButtonStyled>
            <UploadedImageActionButtonStyled $hbg="var(--main-color)" onClick={handleOn}>
              {t("addCar.photo.save")}
            </UploadedImageActionButtonStyled>
          </SaveActionsWrapperStyled>
          <CropAreaStyled>
            <Cropper
              image={image.image || ""}
              crop={crop}
              cropShape="round"
              zoom={zoom}
              aspect={CROP_AREA_ASPECT}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              rotation={rotate}
              style={{
                containerStyle: {
                  background: "rgb(46, 46, 46)",
                },
                cropAreaStyle: {
                  // background: "rgb(255, 255, 255)",
                },
                mediaStyle: {
                  background: "rgb(255, 255, 255)",
                },
              }}
            />
          </CropAreaStyled>
          <CropActionsWrapperStyled>
            <Slider
              // defaultValue={1.5}
              tooltip={{ formatter: null }}
              max={3}
              min={1}
              step={0.01}
              value={zoom}
              onChange={handleSliderChange}
            />
            <RotateActionWrapperStyled>
              <RotateButtonStyled onClick={handleRotate(90)}>
                <svg
                  width="19.692139"
                  height="17.000000"
                  viewBox="0 0 19.6921 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs />
                  <path
                    id="replay"
                    d="M19.69 8.5C19.69 9.67 19.46 10.78 19.02 11.81C18.57 12.84 17.97 13.74 17.2 14.51C16.43 15.27 15.53 15.88 14.5 16.33C13.47 16.77 12.37 17 11.19 17L11.19 15.5C13.14 15.5 14.79 14.82 16.15 13.46C17.51 12.1 18.19 10.45 18.19 8.5C18.19 6.55 17.51 4.89 16.15 3.53C14.79 2.17 13.14 1.5 11.19 1.5C9.24 1.5 7.58 2.17 6.22 3.53C4.87 4.89 4.19 6.55 4.19 8.5L4.19 8.76L5.78 7.17L6.86 8.23L3.43 11.65L0 8.21L1.08 7.15L2.69 8.76L2.69 8.5C2.69 7.32 2.91 6.21 3.36 5.18C3.8 4.15 4.41 3.25 5.18 2.48C5.94 1.72 6.84 1.11 7.87 0.66C8.9 0.22 10.01 0 11.19 0C12.37 0 13.47 0.22 14.5 0.66C15.53 1.11 16.43 1.72 17.2 2.48C17.97 3.25 18.57 4.15 19.02 5.18C19.46 6.21 19.69 7.32 19.69 8.5Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </svg>
              </RotateButtonStyled>
              <RotateButtonStyled onClick={handleRotate(-90)}>
                <svg
                  width="19.692139"
                  height="17.000000"
                  viewBox="0 0 19.6921 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs />
                  <path
                    id="forward_media"
                    d="M0 8.5C0 7.32 0.22 6.21 0.66 5.18C1.11 4.15 1.72 3.25 2.48 2.48C3.25 1.72 4.15 1.11 5.18 0.66C6.21 0.22 7.32 0 8.5 0C9.67 0 10.78 0.22 11.81 0.66C12.84 1.11 13.74 1.72 14.51 2.48C15.27 3.25 15.88 4.15 16.33 5.18C16.77 6.21 17 7.32 17 8.5L17 8.76L18.6 7.15L19.69 8.21L16.25 11.65L12.82 8.23L13.91 7.17L15.5 8.76L15.5 8.5C15.5 6.55 14.82 4.89 13.46 3.53C12.1 2.17 10.45 1.5 8.5 1.5C6.54 1.5 4.89 2.17 3.53 3.53C2.17 4.89 1.5 6.55 1.5 8.5C1.5 10.45 2.17 12.1 3.53 13.46C4.89 14.82 6.54 15.5 8.5 15.5L8.5 17C7.32 17 6.21 16.77 5.18 16.33C4.15 15.88 3.25 15.27 2.48 14.51C1.72 13.74 1.11 12.84 0.66 11.81C0.22 10.78 0 9.67 0 8.5Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </svg>
              </RotateButtonStyled>
            </RotateActionWrapperStyled>
          </CropActionsWrapperStyled>
        </CropWrapperStyled>
      </Modal>
    </ConfigProvider>
  );
};
