import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { createYearOptions } from "../../../helpers/createYearOptions";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { SelectStyled } from "../../common/Select";
import { useSearch } from "../../search/utils/useSearch";
import { FromToEnum } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";

const YearSelect = SelectStyled();

const YearPickerWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
  & > div {
    flex-grow: 1;
  }
`;

export const YearSearch = () => {
  return (
    <YearPickerWrapperStyled>
      <YearPickerComp type={FromToEnum.FROM} />
      <YearPickerComp type={FromToEnum.TO} />
    </YearPickerWrapperStyled>
  );
};

export const YearPickerComp = React.memo(
  ({ type, isSearch }: { type: FromToEnum; isSearch?: boolean }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { manageSearch } = useSearch();
    const [options, setOptions] = useState<{ label: number; value: number }[]>([]);

    const year = useAppSelector((state) => state.filters.selectedData.year);

    const handleSelect = (value: string) => {
      dispatch(FiltersActions.setYear({ type, value: Number(value) }));
      if (isSearch)
        manageSearch(`year${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    };

    useEffect(() => {
      setOptions(createYearOptions().reverse());
    }, []);

    const handleClear = () => {
      dispatch(FiltersActions.setYear({ type }));
    };

    useEffect(() => {
      if (type === FromToEnum.TO && year?.from) {
        setOptions((prev) =>
          prev.map((option) => {
            if (year.from && Number(option.value) < year.from) {
              return {
                ...option,
                disabled: true,
              };
            }
            return { ...option, disabled: false };
          }),
        );
        if (type === FromToEnum.TO && year.to && year.from > year.to) {
          dispatch(FiltersActions.setYear({ type, value: year.from }));
          if (isSearch) {
            manageSearch(
              `year${type.slice(0, 1).toUpperCase()}${type.slice(1)}`,
              String(year.from) || "",
            );
          }
        }
      }
    }, [year, type, dispatch, manageSearch, isSearch]);

    return (
      <YearSelect
        options={options}
        value={year?.[type]}
        onSelect={handleSelect}
        onClear={handleClear}
        showSearch
        allowClear
        placeholder={t(type)}
        notFoundContent={t("no-data")}
      />
    );
  },
);
